import React from 'react';
import { connect } from 'react-redux';
import UserDashboard from '../Components/UserDashboard';
import AdminDashboard from '../Components/AdminDashboard';
import SupervisorDashboard from '../Components/SupervisorDashboard';
import { USER_TYPE } from '../Utils/Constants';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};

const Dashboard = ({...props}) => {
    const { user } = props;

    const fetchDashboard = () => {
        switch (user.role) {
            case USER_TYPE.USER:
                return <UserDashboard />;
            case USER_TYPE.ADMIN:
                return <AdminDashboard />;
            case USER_TYPE.SUPERVISOR:
                return <SupervisorDashboard />;
        }
    };

    return (
        <div>
            {fetchDashboard()}
        </div>
    )
}

export default connect(mapStateToProps)(Dashboard);
