import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";

import Table from '../Components/Table';
import Loader from '../Components/Loader';
import AuthProvider from '../Utils/AuthProvider';
import TopSection from '../Components/TopSection';

const companiesTableCols = (redirectToUrl) => {
    return {
        "NAME": {
            name: "Nume"
        },
        "usersTests": {
            name: "Teste Utilizatori",
            flexBasis: 20,
            handle: (row) => {
                return <button 
                    className="btn btn-primary mr-md-1 w-100" 
                    type="button" 
                    onClick={() => redirectToUrl(`/test-statistics?companyId=${row.id}`)}
                >
                    Statistici teste
                </button>
            }
        },
        "usersDocuments": {
            name: "Documente Utilizatori",
            flexBasis: 20,
            handle: (row) => {
                return <button 
                    className="btn btn-primary mr-md-1 w-100" 
                    type="button" 
                    onClick={() => redirectToUrl(`/documentation-statistics?companyId=${row.id}`)}
                >
                    Statistici documente
                </button>
            }
        },
    }
};

const Companies = ({...props}) => {
    const history = useHistory();

    const [companiesTableLoading, setCompaniesTableLoading] = useState();
    const [companiesTableData, setCompaniesTableData] = useState([]);
    const [fetchCompaniesTableData, setFetchCompaniesTableData] = useState(false);
    
    useEffect(() => {
        const getData = async () => {
            setCompaniesTableLoading(true);
        
            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/companies/'
            });
            
            if (!res.isOk) {
                return setCompaniesTableLoading(false);
            }

            setCompaniesTableLoading(false);
            return setCompaniesTableData(res.data);            
        };

        getData();
    }, [fetchCompaniesTableData]);

    const redirectToUrl = (url) => {
        history.push(url);
    };

    return (
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Firme"/>

                <section className="content">
                    <div className="container-fluid">

                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Lista Firme</h3>
                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${companiesTableLoading ? "loading" : ""}`}>
                                    <Table 
                                        cols={companiesTableCols(redirectToUrl)} 
                                        data={companiesTableData} 
                                        customClasses="break-md"
                                    />
                                </div>
                                {companiesTableLoading && <Loader />}
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    );
}

export default Companies;
