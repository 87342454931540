import React from 'react';
import Loader from '../Components/Loader';

const TakeTestCard = ({...props}) => {
    const { data, loading, mode } = props;

    let cardTitle = null;
    switch (mode) {
        case 'preview':
            cardTitle = 'Vizualizeaza test';
            break;
        case 'take':
            cardTitle = 'Sustine test';
            break;
        default:
            cardTitle = 'Vizualizeaza test';
            break;
    }

    if (!data) {
        return null;
    } else if (loading) {
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{cardTitle}</h3>
                </div>
                <div className="card-body">
                    <div className={`card-body-wrapper loading`}>
                    </div>
                    <Loader/>
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">{cardTitle}</h3>
            </div>
            <div className="card-body">
                <div className='card-body-wrapper'>
                    <div className="col-12">

                        <form>
                            <h3 className="text-center mt-2">{data.title}</h3>
                            <h6 className="text-center font-italic mb-4">Minim {data.points} de puncte</h6>
                            {data.articles.map((article, articleNr) => {
                                return (
                                    <div key={articleNr} className="card card-info card-outline">    
                                        <div className="card-header">
                                            <h4 className="card-title w-100">
                                                {`${articleNr+1}. ${article.question}`}
                                            </h4>
                                        </div>
                                        <div>
                                            <div className="card-body">
                                                {article.answers.map((answer, answerNr) => {
                                                    return (
                                                        <div key={answerNr} className="custom-control custom-checkbox mb-2">
                                                            <input 
                                                                className="custom-control-input" 
                                                                type="checkbox" 
                                                                disabled={true}
                                                                id={`customCheckbox${''+articleNr+answerNr}`} />
                                                            <label 
                                                                htmlFor={`customCheckbox${''+articleNr+answerNr}`} 
                                                                className="custom-control-label">
                                                                <strong>{`${answer.index})`}</strong> {`${answer.description}`}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TakeTestCard;