import React, {useState, useRef} from 'react';
import AuthProvider from '../Utils/AuthProvider';
import { VALIDATION_MESSAGES } from '../Utils/Constants';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import Loader from '../Components/Loader';
import { setUser } from '../Actions/MainActions';
import { Redirect, useParams } from 'react-router-dom';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};  

const Register = ({...props}) => { 
    const { code } = useParams(); 
    const { register, handleSubmit, errors, watch } = useForm();
    const [loading, setLoading] = useState(null);

    const password = useRef({});
    password.current = watch("password", "");

    if (props.user) {
        return <Redirect to="/" />
    }

    const onSubmit = async (data) => {
        if (!code) return;
        
        setLoading(true);
        
        let res = await AuthProvider.register({
            code: code,
            name: data.name,
            password: data.password
        });
        
        if (!res.isOk) {
            return setLoading(false);
        }

        props.setUser(res.data.user);

        setLoading(false);
    }

    return (
        <div className="login-box">
            <div className="card card-secondary">
                <div className="card-header pt-2 pb-2">
                    <i className="fas fa-circle text-danger" style={{fontSize: "1rem"}}></i>
                    <i className="fas fa-circle text-warning pl-1" style={{fontSize: "1rem"}}></i>
                    <i className="fas fa-circle text-success pl-1" style={{fontSize: "1rem"}}></i>
                </div>
                <div className="card-body position-relative">
                    <div className={`card-body-wrapper ${loading ? "loading" : ""}`}>
                        <div className="row mb-3">
                            <img src="../img/logo.jfif" className="ml-auto mr-auto" style={{height: '3.5rem', width: '75%'}}/>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="input-group mb-3">
                                <input 
                                    type="text" 
                                    name="name" 
                                    className={`form-control ${errors.name ? "is-invalid" : ""}`} 
                                    placeholder="Numele complet" 
                                    ref={register({ required: true })}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-user" />
                                    </div>
                                </div>
                                {errors.name && <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div>}
                            </div>

                            <div className="input-group mb-3">
                                <input 
                                    type="password" 
                                    name="password" 
                                    autoComplete="on"
                                    className={`form-control ${errors.password ? "is-invalid" : ""}`} 
                                    placeholder="Parola" 
                                    ref={register({ required: true })}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                    </div>
                                </div>
                                {errors.password && <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div>}
                            </div>

                            <div className="input-group mb-3">
                                <input 
                                    type="password" 
                                    name="confirmPassword" 
                                    autoComplete="on"
                                    className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`} 
                                    placeholder="Confirmare Parola" 
                                    ref={register({
                                        validate: value =>
                                          value === password.current || "Parolele nu corespund"
                                    })}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-lock" />
                                    </div>
                                </div>
                                {errors.confirmPassword && <div className="invalid-feedback">Parolele nu corespund</div>}
                            </div>

                            <div className="row">
                                {/* <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        Tine-ma minte
                                    </label>
                                    </div>
                                </div> */}
                                <div className="col text-center">
                                    <button type="submit" className="btn btn-secondary pl-4 pr-4">Login</button>
                                </div>
                            </div>
                        </form>
                        {/* <div className="social-auth-links text-center mt-2 mb-3">
                            <a className="btn btn-block btn-primary">
                            <i className="fab fa-facebook mr-2" /> Sign in using Facebook
                            </a>
                            <a className="btn btn-block btn-danger">
                            <i className="fab fa-google-plus mr-2" /> Sign in using Google+
                            </a>    
                        </div> */}
                        {/* <p className="mt-1">
                            <a href="forgot-password.html">Am uitat parola</a>
                        </p> */}
                        {/* <p className="mb-0">
                            <a href="register.html" className="text-center">Register a new membership</a>
                        </p> */}
                    </div>

                    {loading && <Loader />}
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, {
    setUser
})(Register);