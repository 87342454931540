import React, {useState} from 'react';
import AuthProvider from '../Utils/AuthProvider';
import { VALIDATION_MESSAGES } from '../Utils/Constants';
import * as CONSTANTS from '../Utils/Constants';
import { NotificationManager } from 'react-notifications';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import Loader from '../Components/Loader';
import { setUser } from '../Actions/MainActions';
import { Redirect } from 'react-router-dom';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};  

const ForgotPassword = ({...props}) => { 
    const { register, handleSubmit, errors } = useForm();
    const [loading, setLoading] = useState(null);

    if (props.user) {
        return <Redirect to="/" />
    }

    const onSubmit = async (data) => {
        setLoading(true);

        let res = await AuthProvider.nonAuthReq({
            method: "POST",
            path: 'v1/auth/forgot-password'
        }, data);

        if (!res.isOk) {
            return setLoading(false);
        }

        NotificationManager.success(`Solicitarea a fost trimisa catre ${data.email}`, CONSTANTS.GENERIC_MESSAGE.success.title);
        setLoading(false);
    }

    return (
        <div className="login-box">
            <div className="card card-secondary">
                <div className="card-header pt-2 pb-2">
                    <i className="fas fa-circle text-danger" style={{fontSize: "1rem"}}></i>
                    <i className="fas fa-circle text-warning pl-1" style={{fontSize: "1rem"}}></i>
                    <i className="fas fa-circle text-success pl-1" style={{fontSize: "1rem"}}></i>
                </div>
                <div className="card-body position-relative">
                    <div className={`card-body-wrapper ${loading ? "loading" : ""}`}>
                        <div className="row mb-3">
                            <img src="../img/logo.jfif" className="ml-auto mr-auto" style={{height: '3.5rem', width: '75%'}}/>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="input-group mb-3">
                                <input 
                                    type="text" 
                                    name="email" 
                                    className={`form-control ${errors.email ? "is-invalid" : ""}`} 
                                    placeholder="Email" 
                                    ref={register({ required: true })}/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                    <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                {errors.email && <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div>}
                            </div>

                            <div className="row">
                                <div className="col text-center">
                                    <button type="submit" className="btn btn-secondary pl-4 pr-4">Solicita Resetare</button>
                                </div>
                            </div>

                        </form>
                    </div>

                    {loading && <Loader />}
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, {
    setUser
})(ForgotPassword);