import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash'
import { VALIDATION_MESSAGES, GENERIC_MESSAGE } from '../Utils/Constants';
import Table from '../Components/Table';
import TestTemplateCard from '../Components/TestTemplateCard';
import TakeTestCard from '../Components/TakeTestCard';
import Loader from '../Components/Loader';
import AuthProvider from '../Utils/AuthProvider';
import { NotificationManager } from 'react-notifications';
import TopSection from '../Components/TopSection';

const testTemplatesCols = (handleChangeTestTemplateMode, handleDeleteTestTemplate) => {
    return {
        "author": {
            name: "Autor",
            flexBasis: 10
        },
        "companyName": {
            name: "Companie",
            flexBasis: 15
        },
        "name": {
            name: "Titlu"
        },
        "minPoints": {
            name: "Punctaj minim",
            flexBasis: 8
        },
        "maxPoints": {
            name: "Punctaj maxim",
            flexBasis: 8
        },
        "createDate": {
            name: "Data crearii",
            flexBasis: 14
        },
        "action": {
            name: "Actiuni",
            flexBasis: 25,
            handle: (row) => {
                return <div>
                    <button 
                        className="btn btn-warning mr-md-1" 
                        type="button" 
                        onClick={(e) => {
                            handleChangeTestTemplateMode('edit', row)
                        }}>
                        Editeaza
                    </button>
                    <button 
                        className="btn btn-info mr-md-1" 
                        type="button" 
                        onClick={(e) => {
                            handleChangeTestTemplateMode('preview', row)
                        }}>
                        Vizualizeaza
                    </button>
                    <button 
                        className="btn btn-danger" 
                        type="button" 
                        onClick={(e) => {
                            handleDeleteTestTemplate(row)
                        }}>
                        Sterge
                    </button>
                </div>
            }
        }
    }
};

const testTemplateInitData = {
    id: -1,
    title: null,
    company: '',
    points: null,
    articles: [{
        question: null,
        answers: [{
            index: "A",
            description: null,
            correct: false,
        }]
    }]
};

const TestTemplates = ({...props}) => {
    const [testTemplatesLoading, setTestTemplatesLoading] = useState(false);
    const [fetchTestTemplates, setFetchTestTemplates] = useState(true);
    const [testTemplateData, setTestTemplateData] = useState([]);
    
    const [takeTestLoading, setTakeTestLoading] = useState(false);

    const [testTemplateCardMode, setTestTemplateCardMode] = useState('add');

    const [testTemplateLoading, setTestTemplateLoading] = useState(false);
    const [testTemplate, setTestTemplate] = useState(_.cloneDeep(testTemplateInitData));

    const testTemplateCardRef = useRef(null);
    const takeTestCardRef = useRef(null);

    const [testTemplateErrors, setTestTemplateErrors] = useState({
        title: null,
        company: '',
        points: null,
        articles: [{
            question: null,
            answers: [{
                description: null,
                correct: null,
            }]
        }]
    });
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const getData = async () => {
            setTestTemplatesLoading(true);
            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/tests/template'
            });
            if (!res.isOk) {
                return setTestTemplatesLoading(false);
            }

            setTestTemplateData(res.data);

            res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/companies/'
            });
            
            if (!res.isOk) {
                return setTestTemplatesLoading(false);
            }

            setCompanies(res.data);
            
            return setTestTemplatesLoading(false);
        };
        getData();
    }, [fetchTestTemplates]);

    const appendArticle = () => {
        let articles = testTemplate.articles;
        articles.push({
            question: null,
            answers: [{
                index: "A",
                description: null,
                correct: false,
            }]
        });
        return setTestTemplate(() => ({
            ...testTemplate,
            articles
        }))
    };

    const appendAnswerOption = (articleNr) => {
        let articles = testTemplate.articles;
        let answerIndex = String.fromCharCode(65 + articles[articleNr].answers.length);
        articles[articleNr].answers.push({
            index: answerIndex,
            description: null,
            correct: false,
        });
        return setTestTemplate(testTemplate => ({
            ...testTemplate,
            articles
        }));
    };

    const removeAnswerOption = (articleNr, answerNr) => {
        if (testTemplate.articles[articleNr].answers.length <= 1) {
            return;
        }
        let articles = testTemplate.articles;
        articles[articleNr].answers.splice(answerNr, 1);
        let answerRecalculatedIndexes = articles[articleNr].answers.map((value, index) => {
            return {
                index:  String.fromCharCode(65 + index),
                description: value.description,
                correct: value.correct,
            };
        });    
        articles[articleNr].answers = answerRecalculatedIndexes;
        return setTestTemplate(testTemplate => ({
            ...testTemplate,
            articles    
        }));
    };

    const removeArticle = (articleNr) => {
        if (testTemplate.articles.length <= 1) {
            return;
        }
        let articles = testTemplate.articles;
        articles.splice(articleNr, 1);
        return setTestTemplate(() => ({
            ...testTemplate,
            articles
        }));
    };

    const handleAddTestTemplateChange = (e, articleNr, answerNr) => {
        const { name, value, checked } = e.target;
        if (testTemplate.hasOwnProperty(name)) {
            return setTestTemplate(testTemplate => ({
                ...testTemplate,
                [name]: value
            }));
        }
        let articles = testTemplate.articles;
        if (articles[articleNr].hasOwnProperty(name)) {
            articles[articleNr][name] = value;

        }
        else if (articles[articleNr].answers[answerNr].hasOwnProperty(name)) {
            if (checked === null || typeof(checked) === 'undefined') {
                articles[articleNr].answers[answerNr][name] = value;
                
            } else {
                articles[articleNr].answers[answerNr][name] = !!checked;
            }
        }
        return setTestTemplate(testTemplate => ({
            ...testTemplate,
            articles
        }));
    };

    const handleChangeTestTemplateMode = async (mode, row) => {
        setTestTemplateErrors({
            title: null,
            company: '',
            points: null,
            articles: [{
                question: null,
                answers: [{
                    description: null,
                    correct: null,
                }]
            }]
        });
        
        let res = null;
        switch (mode) {
            case 'add':
                setTestTemplate(_.cloneDeep(testTemplateInitData));
                setTestTemplateCardMode('add');
                testTemplateCardRef?.current?.scrollIntoView({behavior: "smooth"});
            break;
            case 'edit':
                setTestTemplateLoading(true);
                res = await AuthProvider.authReq({
                    method: "GET",
                    path: 'v1/tests/template/' + row.id
                });
                if (!res.isOk) {
                    return setTestTemplateLoading(false);
                }
                setTestTemplate({
                    ...res.data.data,
                    company: parseInt(res.data.company),
                    id: row.id
                });
                setTestTemplateCardMode('edit');
                setTestTemplateLoading(false);
                testTemplateCardRef?.current?.scrollIntoView({behavior: "smooth"});
            break;
            case 'preview':
                setTakeTestLoading(true);
                setTestTemplateCardMode('preview');
                res = await AuthProvider.authReq({
                    method: "GET",
                    path: 'v1/tests/template/' + row.id
                }); 
                if (!res.isOk) {
                    return setTakeTestLoading(false);
                }
                setTestTemplate(res.data.data);
                setTakeTestLoading(false);
                takeTestCardRef?.current?.scrollIntoView({behavior: "smooth"});
            break;
            default:
                break;
        }        
    };  

    const validateTestTemplateForm = () => {
        let errors = {
            articles: []
        };
        let hasError = false;
        if (!testTemplate.title) {
            errors.title = VALIDATION_MESSAGES.required;
            hasError = true;
        }
        if (!testTemplate.company) {
            errors.company = VALIDATION_MESSAGES.required;
            hasError = true;
        }
        if (!testTemplate.points && testTemplate.points !== 0) {
            errors.points = VALIDATION_MESSAGES.required;
            hasError = true;
        }
        testTemplate.articles.map((article, articleKey) => {
            if (!errors.articles[articleKey]) {
                errors.articles[articleKey] = {
                    question: null,
                    answers: []
                };
            }
            if (!article.question) {
                errors.articles[articleKey].question = VALIDATION_MESSAGES.required;
                hasError = true;
            }
            article.answers.map((answer, answerKey) => {
                if (!errors.articles[articleKey].answers[answerKey]) {
                    errors.articles[articleKey].answers[answerKey] = {};
                }
                if (!answer.description) {
                    errors.articles[articleKey].answers[answerKey].description = VALIDATION_MESSAGES.required;
                    hasError = true;
                }
                if (!!answer.correct) {
                    errors.articles[articleKey].atLeastOneCorrect = true;
                }
            });
        });

        return { hasError, errors };
    };

    const addTestTemplate = async () => {
        setTestTemplateLoading(true);

        let { hasError, errors } = validateTestTemplateForm();

        if (hasError) {
            setTestTemplateErrors(errors);
            return setTestTemplateLoading(false);
        }

        testTemplate.company = parseInt(testTemplate.company);

        let res = await AuthProvider.authReq({
            method: "POST",
            path: 'v1/tests/template'
        }, { template: testTemplate });

        if (!res.isOk) {
            return setTestTemplateLoading(false);
        }

        NotificationManager.success(`Sablonul de test a fost salvat cu succes`, GENERIC_MESSAGE.success.title);
        setFetchTestTemplates(!fetchTestTemplates);
        return setTestTemplateLoading(false);
    };

    const editTestTemplate = async () => {
        if (testTemplate.id === -1) return;

        setTestTemplateLoading(true);

        let { hasError, errors } = validateTestTemplateForm();

        if (hasError) {
            setTestTemplateErrors(errors);
            return setTestTemplateLoading(false);
        }

        let res = await AuthProvider.authReq({
            method: "PUT",
            path: 'v1/tests/template/' + testTemplate.id
        }, { template: testTemplate });

        if (!res.isOk) {
            return setTestTemplateLoading(false);
        }

        NotificationManager.success(`Sablonul de test a fost editat cu succes`, GENERIC_MESSAGE.success.title);
        setFetchTestTemplates(!fetchTestTemplates);
        return setTestTemplateLoading(false);
    };

    const onTestTemplateSubmit = async () => {
        switch (testTemplateCardMode) {
            case 'add':
                return addTestTemplate();
            case 'edit':
                return editTestTemplate();
            default: return;
        }
    };

    const handleDeleteTestTemplate = async (row) => {
        setTestTemplatesLoading(true);
        
        let res = await AuthProvider.authReq({
            method: "DELETE",
            path: 'v1/tests/template/' + row.id
        });

        if (!res.isOk) return setTestTemplatesLoading(false);
        setFetchTestTemplates(!fetchTestTemplates);
        NotificationManager.success(`Sablonul de test a fost sters cu succes`, GENERIC_MESSAGE.success.title);
    };
    
    return (
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Sabloane Teste"/>
                <section className="content">
                    <div className="container-fluid">

                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Lista Sabloane Test</h3>
                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${testTemplatesLoading ? "loading" : ""}`}>
                                    <Table 
                                        cols={testTemplatesCols(handleChangeTestTemplateMode, handleDeleteTestTemplate)} 
                                        data={testTemplateData} 
                                        customClasses="break-md"
                                    />
                                    <button 
                                        className="btn btn-success mt-md-3 w-100" 
                                        type="button"
                                        onClick={() => handleChangeTestTemplateMode('add')}>
                                        Adauga sablon test
                                    </button>
                                </div>
                                {testTemplatesLoading && <Loader />}
                            </div>
                        </div>

                        <section ref={testTemplateCardRef}>
                            {testTemplateCardMode !== 'preview' &&
                            <TestTemplateCard 
                                data={testTemplate} 
                                companies={companies}
                                errors={testTemplateErrors}
                                loading={testTemplateLoading}
                                mode={testTemplateCardMode}
                                addArticle={appendArticle}
                                removeArticle={removeArticle}
                                addAnswer={appendAnswerOption}
                                removeAnswer={removeAnswerOption}
                                onChange={handleAddTestTemplateChange}
                                submit={onTestTemplateSubmit}
                            >/</TestTemplateCard>}
                        </section>
                        
                        <section ref={takeTestCardRef}>
                            {testTemplateCardMode === 'preview' &&
                            <TakeTestCard 
                                mode={testTemplateCardMode}
                                data={testTemplate}
                                loading={takeTestLoading}
                            >/</TakeTestCard>}
                        </section>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default TestTemplates;