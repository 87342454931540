import { createStore } from 'redux';
import reducers from '../Reducers';

export function configureStore(initialState) {
    const store = createStore(
        reducers,
        initialState
    );
    return store;
}
