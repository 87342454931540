import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

import Loader from '../Components/Loader';
import AuthProvider from '../Utils/AuthProvider';
import TopSection from './TopSection';
import { BASE_URL } from '../Utils/Constants';

const SupervisorDashboard = ({...props}) => {
    const history = useHistory();

    const [summaryData, setSummaryData] = useState(null);
    const [summaryDataLoading, setSummaryDataLoading] = useState(null);
    const [summaryDataFetch, setSummaryDataFetch] = useState(null);

    useEffect(() => {
        const getData = async () => {
            setSummaryDataLoading(true);

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/dashboard/summary/supervisor'
            });

            if (!res.isOk) {
                return setSummaryDataLoading(false);
            }

            setSummaryData(res.data);
            setSummaryDataLoading(false);
        };

        getData();
    }, [summaryDataFetch]);

    const redirectToTestStatisticsPage = () => {
        history.push('/test-statistics/');
    };

    const redirectToDocumentStatisticsPage = () => {
        history.push('/documentation-statistics/');
    };

    const getTestProperties = (test) => {
        let testProperties = {
            score: test.score || 0,
        };

        if (!test.score && test.score !== 0) {
            testProperties.badge = <span className="badge badge-danger mb-1 result-badge">Nesustinut</span>
            testProperties.progressBar = <div className="progress-bar bg-danger" style={{width: '0%'}}></div>
            return testProperties;
        } 

        let progress = parseFloat((test.score / test.maxPoints) * 100).toFixed(0);
        
        if (!test.passed) {
            testProperties.badge = <span className="badge badge-warning mb-1 result-badge">Respins</span>
            testProperties.progressBar = <div className="progress-bar bg-warning" style={{width: `${progress}%`}}></div>
        } else {
            testProperties.badge = <span className="badge badge-success mb-1 result-badge">Admis</span>
            testProperties.progressBar = <div className="progress-bar bg-success" style={{width: `${progress}%`}}></div>
        }

        return testProperties;
    };

    const redirectToTestPage = (testTemplateId) => {
        history.push('/test/' + testTemplateId);
    };

    const handleOpenDocument = async (documentId) => {
        let res = await AuthProvider.authReq({
            method: "GET",
            path: `v1/documentation/template/${documentId}/metered`
        });
        
        if (!res.isOk) return;
        
        let url = res.data;

        return window.open(`${BASE_URL}v1/documentation/preview/${url}`, "_blank");
    };
    
    let noOfUsers = 0;
    let usersWithFailedTests = [];
    let usersWithUnattendedDocumentation = [];
    let usersWithUnattendedTests = [];
    let tests = [];
    let documents = [];

    if (summaryData) {
        noOfUsers = summaryData.noOfUsers || 0;
        if (summaryData.usersWithFailedTests) {
            usersWithFailedTests = Object.values(summaryData.usersWithFailedTests);
        }
        if (summaryData.usersWithUnattendedDocumentation) {
            usersWithUnattendedDocumentation = Object.values(summaryData.usersWithUnattendedDocumentation);
        }
        if (summaryData.usersWithUnattendedTests) {
            usersWithUnattendedTests = Object.values(summaryData.usersWithUnattendedTests);
        }

        if (summaryData.tests) {
            tests = Object.values(summaryData.tests);
        }
        if (summaryData.documents) {
            documents = Object.values(summaryData.documents);
        }
    }

    return (
        <div className="content-wrapper">
            <TopSection pageTitle="Dashboard"/>
            <section className="content">
                <div className="container-fluid">
                    
                    <div className="callout callout-info col-12">
                        <h5>{`Utilizatori sub supervizare (${noOfUsers})`}</h5>
                        <p>In rubrica de mai jos puteti vizualiza statusul utilizatorilor care necesita atentie</p>

                        <div className={`row ${summaryDataLoading ? 'loading' : ''}`}>

                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className={`card card-${!usersWithUnattendedTests.length ? 'success' : 'danger'}`}>

                                    <div className="card-header">
                                        <div className="card-title">

                                            <i className={`fas fa-${!usersWithUnattendedTests.length ? 'check' : 'exclamation'} mr-2`}></i>

                                            {`Utilizatori cu teste nesustinute (${usersWithUnattendedTests.length} / ${noOfUsers})`}
                                        </div>
                                    </div>
                                    
                                    <div className="card-body">
                                        {_.map(usersWithUnattendedTests, (user, index) => {
                                            return <li key={index}>{user.name} ({user.email})</li>;
                                        })}
                                        {!usersWithUnattendedTests.length && <li>Totul in regula</li>}
                                    </div>

                                    <div className="card-footer">
                                        <button 
                                            className="btn btn-secondary w-100" 
                                            type="button" 
                                            onClick={() => redirectToTestStatisticsPage()}
                                        >
                                            Mai multe detalii
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className={`card card-${!usersWithFailedTests.length ? 'success' : 'warning'}`}>

                                    <div className="card-header">
                                        <div className="card-title">

                                            <i className={`fas fa-${!usersWithFailedTests.length ? 'check' : 'exclamation'} mr-2`}></i>

                                            {`Utilizatori cu teste la care nu s-a atins punctajul minim (${usersWithFailedTests.length} / ${noOfUsers})`}
                                        </div>
                                    </div>
                                    
                                    <div className="card-body">
                                        <ul>
                                            {_.map(usersWithFailedTests, (user, index) => {
                                                return <li key={index}>{user.name} ({user.email})</li>;
                                            })}
                                            {!usersWithFailedTests.length && <li>Totul in regula</li>}
                                        </ul>
                                    </div>

                                    <div className="card-footer">
                                        <button 
                                            className="btn btn-secondary w-100" 
                                            type="button" 
                                            onClick={() => redirectToTestStatisticsPage()}
                                        >
                                            Mai multe detalii
                                        </button>
                                    </div>


                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className={`card card-${!usersWithUnattendedDocumentation.length ? 'success' : 'danger'}`}>

                                    <div className="card-header">
                                        <div className="card-title">

                                            <i className={`fas fa-${!usersWithUnattendedDocumentation.length ? 'check' : 'exclamation'} mr-2`}></i>

                                            {`Utilizatori cu documentatie neparcursa  (${usersWithUnattendedDocumentation.length} / ${noOfUsers})`}
                                        </div>
                                    </div>
                                    
                                    <div className="card-body">
                                        <ul>
                                            {_.map(usersWithUnattendedDocumentation, (user, index) => {
                                                return <li key={index}>{user.name} ({user.email})</li>;
                                            })}
                                            {!usersWithUnattendedDocumentation.length && <li>Totul in regula</li>}
                                        </ul>
                                    </div>

                                    <div className="card-footer">
                                        <button 
                                            className="btn btn-secondary w-100" 
                                            type="button" 
                                            onClick={() => redirectToDocumentStatisticsPage()}
                                        >
                                            Mai multe detalii
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {summaryDataLoading && <Loader />}

                    </div>

                    <div className="callout callout-info col-12">
                        <h5>Documentatie</h5>
                        <p>In rubrica de mai jos puteti revizui si vizualiza documentele curente</p>
                        <div className="row">
                            {documents && documents.map((document) => {
                                return (
                                    <div key={document.id} className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="card card-info card-outline">    
                                            <div className="card-header">
                                                <h4 className="card-title w-100">
                                                    <strong>Titlu:</strong> {document.title}
                                                </h4>
                                            </div>
                                            <div className="card-body pt-1 pb-1">
                                                <div className="info-box m-0 pl-0 pr-0 dashboard-document-info-box" style={{boxShadow: 'none', background: 'transparent', borderRadius: 0}}>
                                                    <span className="info-box-icon bg-danger elevation-1">
                                                        <i className="far fa-file-pdf" />
                                                    </span>
                                                    <div className="info-box-content">
                                                        <span className="info-box-text">{document.extension}</span>
                                                        <span className="info-box-number">Categorie: {document.category}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button 
                                                    className="btn btn-success w-100"
                                                    onClick={() => handleOpenDocument(document.id)}
                                                >
                                                    Vizualizeaza
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="callout callout-info col-12">
                        <h5>Rezultate teste</h5>
                        <p>In rubrica de mai jos puteti revizui rezultatele testelor sustinute cat si testele pe care nu le-ati sustinut inca</p>

                        <div className="row">
                            {tests && tests.map((test) => {
                                let testProperties = getTestProperties(test);

                                return (
                                    <div key={test.templateId} className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="card card-info card-outline">    
                                            <div className="card-header">
                                                <h4 className="card-title w-100">
                                                    {test.title}
                                                </h4>
                                                <p className="font-italic">Minim {test.minPoints} puncte</p>
                                            </div>
                                            <div>
                                                <div className="card-body">
                                                    <div className="progress-group">
                                                        {testProperties.badge}
                                                        <span className="float-right"><b>{testProperties.score}</b> din {test.maxPoints} Puncte</span>
                                                        <div className="progress progress-sm">
                                                            {testProperties.progressBar}
                                                        </div>
                                                    </div>
                                                    {!!test.registerDate && 
                                                    <p className="w-100 font-italic">Sustinut la data de <strong>{test.registerDate}</strong></p>}
                                                </div>
                                                {<div className="card-footer">
                                                    <button 
                                                        className="btn btn-success w-100"
                                                        onClick={() => redirectToTestPage(test.templateId)}
                                                    >
                                                    { !test.testResultId ? 'Sustineti testul' : 'Refaceti testul' }
                                                    </button>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default SupervisorDashboard;