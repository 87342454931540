import {React, Fragment} from 'react';
import Modal from 'react-bootstrap/Modal';


const SimpleModal = ({...props}) => {
    return (
        <Modal
            show={props.show}
            dialogClassName="modal-90w"
            onHide={() => props.onHide()}
        >
            <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {props.children}
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary" type="button" onClick={() => props.onHide()}>Anuleaza</button>
                <button 
                    className={`btn ${props.confirmBtnClass || 'btn-primary'}`}
                    onClick={() => {props.onConfirm(props.data)}}
                >
                    {props.confirmBtnTitle || "Salveaza"}
                </button>
            </Modal.Footer>

        </Modal>
    )
}

export default SimpleModal;