const GENERIC_MESSAGE = {
    info: {
        title: 'Info',
        msg: ''
    }, 
    success: {
        title: 'Succes',
        msg: ''
    },
    warning: {
        title: 'Atentie',
        msg: ''
    },
    error: {
        title: 'Eroare',
        msg: 'Va rugam incercati mai tarziu'
    }
};
const VALIDATION_MESSAGES = {
    required: 'Acest camp este necesar'
};
const USER_INVITATION_STATUS_DESCRIPTION = {
    INVITATION_SENT: 1,
    INVITATION_EXPIRED: 2,
    USER_CREATED: 3
};

const BASE_URL = 'https://workmanagement.com.ro/';
// const BASE_URL = 'http://127.0.0.1:3001/';

const USER_TYPE = {
    USER: 3,
    ADMIN: 2,
    SUPERVISOR: 4
};
const TEST_STATUS_MAPPING = {
    NOT_TAKEN: 1,
    FAILED: 2,
    SUCCESSFUL: 3
};

export {
    USER_TYPE,
    BASE_URL,
    GENERIC_MESSAGE,
    VALIDATION_MESSAGES,
    USER_INVITATION_STATUS_DESCRIPTION,
    TEST_STATUS_MAPPING
};
