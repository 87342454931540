import React, { useState, useEffect } from 'react';

import Loader from '../Components/Loader';
import Table from '../Components/Table';
import AuthProvider from '../Utils/AuthProvider';
import TopSection from './TopSection';

const documentStatisticsCols = (setUserDocumentsModalData) => {
    return {
        "name": {
            name: "Nume Utilizator",
        },
        "email": {
            name: "Email",
        },
        "docOk": {
            name: "Documentatie citita",
            flexBasis: 10,
            handle: (row) => {
                if (!row.docOk) {
                    return <div className="col text-center"><i className="fas fa-times-circle text-danger" style={{fontSize: "2rem"}}/></div> 
                }

                return <div className="col text-center"><i className="fas fa-check text-success" style={{fontSize: "2rem"}}/></div> 
            }
        },
        "action": {
            name: "Actiuni",
            flexBasis: 15,
            handle: (row) => {
                return <div>
                    <button 
                        className="btn btn-warning mr-md-1 w-100" 
                        type="button" 
                        onClick={(e) => {
                            setUserDocumentsModalData(row);
                        }}
                    >
                        Detalii
                    </button>
                </div>
            }
        }
    };
};

const userDocsModalTableCols = () => {
    return {
        "documentName": {
            name: "Nume Document",
            flexBasis: 80
        },
        "noOfDownloads": {
            name: "Nr. Vizualizari",
            handle: (row) => {
                return <div className="text-center"><strong>{row.noOfDownloads}</strong></div>
            }
        }
    };
};

const DocumentationStatistics = ({...props}) => {
    const [docStats, setDocStats] = useState(null);
    const [docStatsLoading, setDocStatsLoading] = useState(null);
    const [docStatsFetch, setDocStatsFetch] = useState(null);

    const [tableDocStats, setTableDocStats] = useState(null);

    const [userDocsModalData, setUserDocsModalData] = useState(null);

    const userDocumentDetailsModalId = 'userDocumentDetailsModal';
    const userDocumentDetailsModalBtnRef = React.createRef();

    useEffect(() => {
        const getDocStatsData = async () => {
            setDocStatsLoading(true);

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/documentation/statistics/supervisor'
            });

            if (!res.isOk || !Object.keys(res.data).length) {
                return setDocStatsLoading(false);
            }

            setDocStats(res.data);

            let tableData = [];
            for (var userId in res.data) {
                tableData.push({
                    userId: userId, 
                    name: res.data[userId].name,
                    email: res.data[userId].email,
                    docOk: 1 * res.data[userId].docOk
                });
            }

            setTableDocStats(tableData);
            setDocStatsLoading(false);
        };

        getDocStatsData(docStats);
    }, [docStatsFetch]);

    const setUserDocumentsModalData = (row) => {
        if (!row.userId) return;

        setUserDocsModalData({
            name: row.name,
            email: row.email,
            docs: docStats[row.userId].documents || null
        });

        userDocumentDetailsModalBtnRef.current.click();
    };

    return (
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Statistici Documentatie"/>
                <section className="content">
                    <div className="container-fluid">

                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Utilizatori si documente</h3>
                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${docStatsLoading ? "loading" : ""}`}>
                                    <Table 
                                        cols={documentStatisticsCols(setUserDocumentsModalData)} 
                                        data={tableDocStats} 
                                        customClasses="break-md"
                                    />
                                    {/* BEGIN MODAL */}
                                    <div>
                                        <button 
                                            type="button" 
                                            data-toggle="modal" 
                                            data-target={`#${userDocumentDetailsModalId}`} 
                                            ref={userDocumentDetailsModalBtnRef}
                                            style={{display: "none"}}
                                        />
                                        <div 
                                            className="modal fade" 
                                            id={`${userDocumentDetailsModalId}`} 
                                            tabIndex={-1} 
                                            role="dialog" 
                                            aria-labelledby={`${userDocumentDetailsModalId}Label`} 
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-xl" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">

                                                        <h5 
                                                            className="modal-title" 
                                                            id={`${userDocumentDetailsModalId}Label`}
                                                        >
                                                            {`Documentatie ${userDocsModalData?.name} (${userDocsModalData?.email})`}
                                                        </h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>

                                                    </div>
                                                    <div className="modal-body">
                                                        <Table
                                                            cols={userDocsModalTableCols()}
                                                            data={userDocsModalData?.docs}
                                                            customClasses="break-md"
                                                        ></Table>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Inchide</button>                                         
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END MODAL */}
                                </div>
                                {docStatsLoading && <Loader />}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default DocumentationStatistics;
