import React from 'react';
import _ from 'lodash';
import { VALIDATION_MESSAGES } from '../Utils/Constants';
import Loader from '../Components/Loader';

const TestTemplateCard = ({...props}) => {
    const { data, loading, errors, mode } = props;

    let cardTitle = null;
    switch (mode) {
        case 'add':
            cardTitle = 'Adauga sablon test';
            break;
        case 'edit':
            cardTitle = 'Editeaza sablon test';
            break;
        default:
            cardTitle = 'Adauga sablon test';
            break;
    }
    
    if (!data) {
        return null;
    } else if (loading) {
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{cardTitle}</h3>
                </div>
                <div className="card-body">
                    <div className={`card-body-wrapper loading`}>
                        
                    </div>
                    <Loader/>
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row mb-4">
                    <h3 className="card-title">{cardTitle}</h3>
                </div>
                <div className="row mb-2">
                    <div className="input-group col-md-6 col-sm-12 pr-1">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Titlu test</div>
                        </div>
                        <input 
                            type="text" 
                            className={`form-control ${errors.title ? "is-invalid" : ""}`} 
                            name="title" 
                            placeholder="Titlu"
                            value={data.title || ""}
                            onChange={props.onChange} />
                        {errors.title && <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div> }
                    </div>
                    <div className="input-group col-md-6 col-sm-12">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Punctaj necesar pentru a trece testul </div>
                        </div>
                        <input 
                            type="text" 
                            className={`form-control ${errors.points ? "is-invalid" : ""}`} 
                            name="points" 
                            placeholder="Puncte" 
                            value={(!data.points && data.points !== 0) ? "" : data.points}
                            onChange={props.onChange}/>
                        {errors.points && <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div> }
                    </div>
                </div>
            </div>
            <div className="card-body">
                {data.articles.map((article, articleNr) => {
                    return (
                        <div key={articleNr} className="mb-4">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ height: '3.4rem'}}>{`Intrebarea ${articleNr + 1}`}</span>
                                </div>
                                <textarea 
                                    className={`form-control ${errors?.articles?.[articleNr]?.question ? 'is-invalid' : ''}`} 
                                    aria-label="With textarea" 
                                    value={data?.articles?.[articleNr]?.question || ""}
                                    placeholder="Completati intrebarea"
                                    name="question"
                                    onChange={(e) => props.onChange(e, articleNr)}/>
                                {
                                    errors?.articles?.[articleNr]?.question && 
                                    <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div> 
                                }
                            </div>
                            <table className={`table table-bordered table-hover table-responsive-stack add-test-template-table`}>
                                <thead>
                                    <tr className="thead-dark">
                                        <th style={{flexBasis: "5%"}}>Nr.</th>
                                        <th style={{flexBasis: "70%"}}>Optiune raspuns</th>
                                        <th style={{flexBasis: "10%"}}>Corect ?</th>
                                        <th style={{flexBasis: "15%"}}>Actiuni</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.articles[articleNr].answers.map((answer, answerNr) => {
                                        return (
                                            <tr key={answerNr}>
                                                <td style={{flexBasis: "5%"}}>{`${answer.index})`}</td>
                                                <td style={{flexBasis: "70%"}}>
                                                    <textarea 
                                                        aria-label="With textarea" 
                                                        value={data?.articles?.[articleNr]?.answers?.[answerNr]?.description || ""}
                                                        name="description"
                                                        onChange={(e) => props.onChange(e, articleNr, answerNr)}
                                                        className={`form-control ${errors?.articles?.[articleNr]?.answers?.[answerNr]?.description ? "is-invalid" : ""}`}/>
                                                    {
                                                        errors?.articles?.[articleNr]?.answers?.[answerNr]?.description && 
                                                        <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div> 
                                                    }
                                                </td>
                                                <td style={{flexBasis: "10%", textAlign: "center"}}>
                                                    <label>
                                                        <input 
                                                            name="correct" 
                                                            type="checkbox" 
                                                            checked={data?.articles?.[articleNr]?.answers?.[answerNr]?.correct ? true : false}
                                                            onChange={(e) => props.onChange(e, articleNr, answerNr)}/>
                                                        <span />
                                                    </label>
                                                </td>
                                                <td style={{flexBasis: "15%"}}>
                                                    <button 
                                                        className="btn btn-danger" 
                                                        type="button" 
                                                        onClick={() => props.removeAnswer(articleNr, answerNr)}>
                                                        Sterge optiune
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {/* {
                                        !errors?.articles?.[articleNr]?.atLeastOneCorrect && 
                                        <tr><td colSpan="4"><div className="invalid-feedback d-block">Este necesara cel putin o varianta de raspuns corecta</div></td></tr>
                                    } */}
                                    <tr>
                                        <td colSpan="4">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <button className="btn btn-success" type="button" onClick={() => props.addAnswer(articleNr)}>Adauga optiune raspuns</button>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <button 
                                                        className="btn btn-danger" 
                                                        type="button"
                                                        onClick={() => props.removeArticle(articleNr)}>
                                                            Sterge articol
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })}
                <div className="row">
                    <div className="input-group col-md-4">
                        <button 
                            className="btn btn-success" 
                            type="button" 
                            style={{width: "100%"}}
                            onClick={() => props.addArticle()}>
                            Adauga intrebare
                        </button>
                    </div>
                    <div className="input-group col-md-4">
                        <button 
                            className="btn btn-warning" 
                            type="button" 
                            style={{width: "100%"}}>
                            Reseteaza tot chestionarul
                        </button>
                    </div>
                    <div className="input-group col-md-4">
                        <button 
                            className="btn btn-primary" 
                            type="button" 
                            style={{width: "100%"}}
                            onClick={() => props.submit()}>
                            Salveaza tot chestionarul
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestTemplateCard;