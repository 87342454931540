import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { USER_TYPE } from '../Utils/Constants';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};

const menuItems = [
    {
        displayName: "Utilizatori",
        iconClassName: "nav-icon fas fas fa-user",
        path: "/users",
        roles: [USER_TYPE.ADMIN, USER_TYPE.SUPERVISOR]   
    },
    {
        displayName: "Firme",
        iconClassName: "nav-icon far fa-building",
        path: "/companies",
        roles: [USER_TYPE.ADMIN]
    },
    {
        header: true,
        name: "DOCUMENTATIE",
        roles: [USER_TYPE.ADMIN, USER_TYPE.SUPERVISOR]
    },
    {
        displayName: "Documentatie",
        iconClassName: "nav-icon fas fa-book",
        path: "/documentation-templates",
        roles: [USER_TYPE.ADMIN, USER_TYPE.SUPERVISOR]
    },
    {
        displayName: "Statistici Documentatie",
        iconClassName: "nav-icon fas fa-chart-line",
        path: "/documentation-statistics",
        roles: [USER_TYPE.ADMIN, USER_TYPE.SUPERVISOR]
    },
    {
        header: true,
        name: "TESTE",
        roles: [USER_TYPE.ADMIN, USER_TYPE.SUPERVISOR]
    },
    {
        displayName: "Sabloane Teste",
        iconClassName: "nav-icon fas fa-vials",
        path: "/test-templates",
        roles: [USER_TYPE.ADMIN]
    },
    {
        displayName: "Sabloane Teste",
        iconClassName: "nav-icon fas fa-vials",
        path: "/test-template",
        roles: [USER_TYPE.SUPERVISOR]
    },
    {
        displayName: "Statistici Teste",
        iconClassName: "nav-icon fas fa-chart-line",
        path: "/test-statistics",
        roles: [USER_TYPE.ADMIN, USER_TYPE.SUPERVISOR]
    },
];

const Menu = ({...props}) => {
    const renderMenu = () => {
        if (!props.user) {
            return null;
        }
        return menuItems.map((menuItem) => {
            if (menuItem.roles.indexOf(props.user.role) !== -1) {
                if (menuItem.header) {
                    return (
                        <li key={menuItem.name} className="nav-header">{menuItem.name}</li>
                    );
                }
                return (
                    <li key={menuItem.path} className="nav-item">
                        <NavLink className="nav-link" exact={true} to={menuItem.path} activeClassName="active">
                            <i className={menuItem.iconClassName} />
                            <p>
                                {menuItem.displayName}
                            </p>
                        </NavLink>    
                    </li>
                );
            }
            return null;
        })
    };

    if (!props.user) {
        return null;
    }

    let userInfo = props?.user;
    let userName = (userInfo?.name ? userInfo.name : null);
    let supervisorName = (userInfo?.supervisorName ? `${userInfo.supervisorName}` : null);
    let companyName = (userInfo?.companyName ? `${userInfo.companyName}` : null);

    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">

                <div className="pt-2 pb-2" style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                    <i className="fas fa-circle text-danger" style={{fontSize: "1rem"}}></i>
                    <i className="fas fa-circle text-warning pl-1" style={{fontSize: "1rem"}}></i>
                    <i className="fas fa-circle text-success pl-1" style={{fontSize: "1rem"}}></i>
                </div>

                {/* <img src="img/logo.jfif" style={{height: '3.5rem', minWidth: '100%'}}/> */}
                 
                <div className="sidebar">

                    <div className="user-panel mb-3 d-flex">
                        <div className="info pb-3">
                            {userName && <a className="d-block font-weight-bold">{userName}</a>}
                            {supervisorName && <a className="d-block font-weight-bold font-italic">{supervisorName}</a>}
                            {companyName && <a className="d-block font-weight-bold font-italic">{companyName}</a>}
                        </div>
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to="/" activeClassName="active">
                                    <i className="nav-icon fas fa-tachometer-alt" />
                                    <p>
                                        Dashboard
                                    </p>
                                </NavLink>    
                            </li>

                            {renderMenu()}
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}

export default connect(mapStateToProps)(Menu);