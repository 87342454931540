import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

import Loader from '../Components/Loader';
import AuthProvider from '../Utils/AuthProvider';
import TopSection from './TopSection';

const AdminDashboard = ({...props}) => {
    const history = useHistory();

    const [summaryData, setSummaryData] = useState(null);
    const [summaryDataLoading, setSummaryDataLoading] = useState(null);
    const [summaryDataFetch, setSummaryDataFetch] = useState(null);

    useEffect(() => {
        const getData = async () => {
            setSummaryDataLoading(true);

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/dashboard/summary/admin'
            });

            if (!res.isOk) {
                return setSummaryDataLoading(false);
            }

            setSummaryData(res.data);
            setSummaryDataLoading(false);
        };

        getData();
    }, [summaryDataFetch]);

    const redirectToTestStatisticsPage = () => {
        history.push('/test-statistics/');
    };

    const redirectToDocumentStatisticsPage = () => {
        history.push('/documentation-statistics/');
    };
    
    let noOfUsers = 0;
    let usersWithFailedTests = [];
    let usersWithUnattendedDocumentation = [];
    let usersWithUnattendedTests = [];

    if (summaryData) {
        noOfUsers = summaryData.noOfUsers || 0;
        usersWithFailedTests = summaryData.usersWithFailedTests ? Object.values(summaryData.usersWithFailedTests) : [];
        usersWithUnattendedDocumentation = summaryData.usersWithUnattendedDocumentation ? Object.values(summaryData.usersWithUnattendedDocumentation) : [];
        usersWithUnattendedTests = summaryData.usersWithUnattendedTests ? Object.values(summaryData.usersWithUnattendedTests) : [];
    }

    return (
        <div className="content-wrapper">
            <TopSection pageTitle="Dashboard"/>
            <section className="content">
                <div className="container-fluid">
                    
                <div className="callout callout-info col-12">
                        <h5>{`Lista utilizatori (${noOfUsers})`}</h5>
                        <p>In rubrica de mai jos puteti vizualiza statusul utilizatorilor care necesita atentie</p>

                        <div className={`row ${summaryDataLoading ? 'loading' : ''}`}>

                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className={`card card-${!usersWithUnattendedTests.length ? 'success' : 'danger'}`}>

                                    <div className="card-header">
                                        <div className="card-title">

                                            <i className={`fas fa-${!usersWithUnattendedTests.length ? 'check' : 'exclamation'} mr-2`}></i>

                                            {`Utilizatori cu teste nesustinute (${usersWithUnattendedTests.length} / ${noOfUsers})`}
                                        </div>
                                    </div>
                                    
                                    <div className="card-body">
                                        {_.map(usersWithUnattendedTests, (user, index) => {
                                            return <li key={index}>{user.name} ({user.email})</li>;
                                        })}
                                        {!usersWithUnattendedTests.length && <li>Totul in regula</li>}
                                    </div>

                                    <div className="card-footer">
                                        <button 
                                            className="btn btn-secondary w-100" 
                                            type="button" 
                                            onClick={() => redirectToTestStatisticsPage()}
                                        >
                                            Mai multe detalii
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className={`card card-${!usersWithFailedTests.length ? 'success' : 'warning'}`}>

                                    <div className="card-header">
                                        <div className="card-title">

                                            <i className={`fas fa-${!usersWithFailedTests.length ? 'check' : 'exclamation'} mr-2`}></i>

                                            {`Utilizatori cu teste la care nu s-a atins punctajul minim (${usersWithFailedTests.length} / ${noOfUsers})`}
                                        </div>
                                    </div>
                                    
                                    <div className="card-body">
                                        <ul>
                                            {_.map(usersWithFailedTests, (user, index) => {
                                                return <li key={index}>{user.name} ({user.email})</li>;
                                            })}
                                            {!usersWithFailedTests.length && <li>Totul in regula</li>}
                                        </ul>
                                    </div>

                                    <div className="card-footer">
                                        <button 
                                            className="btn btn-secondary w-100" 
                                            type="button" 
                                            onClick={() => redirectToTestStatisticsPage()}
                                        >
                                            Mai multe detalii
                                        </button>
                                    </div>


                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">

                                <div className={`card card-${!usersWithUnattendedDocumentation.length ? 'success' : 'warning'}`}>

                                    <div className="card-header">
                                        <div className="card-title">

                                            <i className={`fas fa-${!usersWithUnattendedDocumentation.length ? 'check' : 'exclamation'} mr-2`}></i>

                                            {`Utilizatori cu documentatie neparcursa  (${usersWithUnattendedDocumentation.length} / ${noOfUsers})`}
                                        </div>
                                    </div>
                                    
                                    <div className="card-body">
                                        <ul>
                                            {_.map(usersWithUnattendedDocumentation, (user, index) => {
                                                return <li key={index}>{user.name} ({user.email})</li>;
                                            })}
                                            {!usersWithUnattendedDocumentation.length && <li>Totul in regula</li>}
                                        </ul>
                                    </div>

                                    <div className="card-footer">
                                        <button 
                                            className="btn btn-secondary w-100" 
                                            type="button" 
                                            onClick={() => redirectToDocumentStatisticsPage()}
                                        >
                                            Mai multe detalii
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {summaryDataLoading && <Loader />}

                    </div>
                    
                </div>
            </section>
        </div>
    );
};

export default AdminDashboard;