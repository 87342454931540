import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import AuthProvider from '../Utils/AuthProvider';
import Loader from './Loader';

const GeneralFilters = ({...props}) => {
    
    const { onChange } = props;
    const { companyId, userRoleId, userId} = props.data;

    const [loading, setLoading] = useState(null);

    const [companyData, setCompanyData] = useState(null);
    const [userRoleData, setUserRoleData] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (_.isUndefined(companyId)) {
            return;
        }

        const getData = async () => {
            if (!loading) {
                setLoading(true);
            }
    
            let query = {};
            if (!!userRoleId) query.userRoleId =  userRoleId;
            if (!!userId) query.userId =  userId;
    
            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/companies/',
                query
            });
    
            if (!res.isOk) {
                return setLoading(false);
            }

            setCompanyData(res.data);
            return setLoading(false);
        };

        getData();
    }, [userRoleId, userId]);

    useEffect(() => {
        if (_.isUndefined(userRoleId)) {
            return;
        }

        const getData = async () => {
            if (!loading) {
                setLoading(true);
            }
    
            let query = {};
            if (!!companyId) query.companyId =  companyId;
            if (!!userId) query.userId =  userId;

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/users/roles',
                query
            });
    
            if (!res.isOk) {
                return setLoading(false);
            }
    
            setUserRoleData(res.data);
            return setLoading(false);
        };

        getData();
    }, [companyId, userId]);

    useEffect(() => {
        if (_.isUndefined(userId)) {
            return;
        }

        const getData = async () => {
            if (!loading) {
                setLoading(true);
            }
            
            let query = {};
            if (!!companyId) query.companyId =  companyId;
            if (!!userRoleId) query.userRoleId =  userRoleId;
            
            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/users/admin',
                query
            });
    
            if (!res.isOk) {
                return setLoading(false);
            }
    
            setUserData(res.data);
            return setLoading(false);
        };

        getData();
    }, [companyId, userRoleId]);

    return (
        <form>

            {loading && <Loader />}

            <div className={`form-row card-body-wrapper ${loading ? 'loading' : ''}`}>

                {!_.isUndefined(companyId) && <div className="form-group col-md-4">
                    <label className="d-none d-sm-block" htmlFor="companyId">Companie</label>
                    <select 
                        className={`form-control`} 
                        id="companyId"
                        name="companyId"
                        value={companyId}
                        onChange={(e) => onChange("companyId", e.target.value)}
                    >
                        <option value={""}>Alege companie...</option>
                        {_.map(companyData, (company) => {
                            return <option 
                                key={company.id} 
                                value={company.id}>{company.NAME}
                            </option>
                        })}
                    </select>
                </div>}

                {!_.isUndefined(userRoleId) && <div className="form-group col-md-4">
                    <label className="d-none d-sm-block" htmlFor="userRoleId">Rol utilizator</label>
                    <select 
                        className={`form-control`} 
                        id="userRoleId"
                        name="userRoleId"
                        onChange={(e) => onChange("userRoleId", e.target.value)}
                    >
                        <option value={""}>Alege rol utilizator...</option>
                        {_.map(userRoleData, (userRole) => {
                            return <option 
                                key={userRole.id} 
                                value={userRole.id}>{userRole.description}
                            </option>
                        })}
                    </select>
                </div>}

                {!_.isUndefined(userId) && <div className="form-group col-md-4">
                    <label className="d-none d-sm-block" htmlFor="userId">Utilizator</label>
                    <select 
                        className={`form-control`} 
                        id="userId"
                        name="userId"
                        onChange={(e) => onChange("userId", e.target.value)}
                    >
                        <option value={""}>Alege utilizator...</option>
                        {_.map(userData, (user) => {
                            return <option 
                                key={user.id} 
                                value={user.id}>{user.name}
                            </option>
                        })}
                    </select>
                </div>}

            </div>

        </form>
    );
};

export default GeneralFilters;