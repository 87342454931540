import axios from 'axios';
import * as CONSTANTS from './Constants';
import {NotificationManager} from 'react-notifications';

const AuthProvider = {
    login : async (data) => {
        return axios.post(CONSTANTS.BASE_URL + 'v1/auth/login', data)
          .then(function (res) {
            localStorage.setItem('user', JSON.stringify(res.data));
            return {
              isOk: true,
              code: res.status,
              data: res.data
            }
          })
          .catch(function (error) {
            if (!error.response) {
              NotificationManager.error(CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
              return {
                isOk: false,
                code: 500
              }
            }
            NotificationManager.error(error.response.data.message || CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
            return {
              isOk: false,
              code: error.response.status || 500
            }
          });
    },

    resetPassword: async (params, data) => {
      let headers = {};

      if (params.headers) {
        for (var key in params.headers) {
            if (!headers[key]) {
              headers[key] = params.headers[key];
            }
        }
      }

      return axios({
        method: params.method,
        url: CONSTANTS.BASE_URL + params.path,
        headers,
        data: data || null,
        params: params.query || null
      })
      .then(function (res) {
        localStorage.setItem('user', JSON.stringify(res.data));
        return {
          isOk: true,
          code: res.status,
          data: res.data
        }
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
          return {
            isOk: false,
            code: 500
          }
        }
        NotificationManager.error(error.response.data.message || CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
        return {
          isOk: false,
          code: error.response.status || 500
        }
      });
    }, 

    register: async (data) => {
      return axios.post(CONSTANTS.BASE_URL + 'v1/auth/register', data)
      .then(function (res) {
        localStorage.setItem('user', JSON.stringify(res.data));
        return {
          isOk: true,
          code: res.status,
          data: res.data
        }
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
          return {
            isOk: false,
            code: 500
          }
        }
        NotificationManager.error(error.response.data.message || CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
        return {
          isOk: false,
          code: error.response.status || 500
        }
      });
    },
    
    logout: () => localStorage.removeItem('user'),
    
    nonAuthReq: async (params, data) => {
      let headers = {};

      if (params.headers) {
        for (var key in params.headers) {
            if (!headers[key]) {
              headers[key] = params.headers[key];
            }
        }
      }

      return axios({
        method: params.method,
        url: CONSTANTS.BASE_URL + params.path,
        headers,
        data: data || null,
        params: params.query || null
      })
      .then(function (res) {
        return {
          isOk: true,
          code: res.status,
          data: res.data
        }
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
          return {
            isOk: false,
            code: 500
          }
        }
        NotificationManager.error(error.response.data.message || CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
        return {
          isOk: false,
          code: error.response.status || 500
        }
      });
    }, 

    authReq : async (params, data) => {
      let token = null;
      try {
        token = JSON.parse(localStorage.getItem("user")).tokens.access.token;
      } catch {
        return {
          isOk: false,
          code: 500
        }
      }

      let headers = { Authorization: `Bearer ${token}` };
      
      if (params.headers) {
        for (var key in params.headers) {
            if (!headers[key]) {
              headers[key] = params.headers[key];
            }
        }
      }

      return axios({
        method: params.method,
        url: CONSTANTS.BASE_URL + params.path,
        headers,
        data: data || null,
        params: params.query || null
      })
      .then((res) => {
        return {
          isOk: true,
          code: res.status,
          data: res.data
        }
      })
      .catch((error) => {
        if (!error.response) {
          NotificationManager.error(CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
          return {
            isOk: false,
            code: 500
          }
        }
        NotificationManager.error(error.response.data.message || CONSTANTS.GENERIC_MESSAGE.error.msg, CONSTANTS.GENERIC_MESSAGE.error.title);
        return {
          isOk: false,
          code: error.response.status || 500
        }
      });
    }
} 

export default AuthProvider;