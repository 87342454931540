import React from 'react';

const Table = ({cols, data, ...props}) => {
    let noOfCols = Object.keys(cols).length; 

    let flexBasisRemainder = Object.values(cols).reduce((flexBasisRemainder, col) => {
        if (col.flexBasis) return {
            number: flexBasisRemainder.number - col.flexBasis,
            count: --flexBasisRemainder.count
        } 
        return flexBasisRemainder;
    }, {
        number: 100,
        count: noOfCols
    });

    let defaultFlexBasis = flexBasisRemainder.count === noOfCols 
        ? 100 / noOfCols
        : flexBasisRemainder.number / flexBasisRemainder.count;

    let flexBasisMap = {};
    for (var key in cols) {
        if (cols[key].flexBasis) {
            flexBasisMap[key] = cols[key].flexBasis + "%";
        } else {
            flexBasisMap[key] = defaultFlexBasis + "%";
        }
    }

    return (
        <table className={`table table-striped table-bordered table-hover table-responsive-stack ${props.customClasses || ""}`}>
            <thead className="thead-dark">
                <tr className="table-responsive-head">
                    {Object.keys(cols).map(key => (
                        <th key={key} style={{flexBasis: flexBasisMap[key]}}>{cols[key].name}</th>    
                    ))}
                </tr>
            </thead>
            <tbody>
                {data && data.map((row, index) => (
                    <tr key={index}>
                        {Object.keys(cols).map(colKey => {
                            if (cols[colKey].handle) {
                                return (
                                    <td key={colKey} style={{flexBasis: flexBasisMap[colKey]}}>
                                        <span className="table-responsive-stack-thead">{cols[colKey].name}:</span>
                                        {cols[colKey].handle(row)}
                                    </td>
                                );
                            }
                            if (!row[colKey] && row[colKey] !== 0) {
                                return null;
                            }
                            return (
                                <td key={colKey} style={{flexBasis: flexBasisMap[colKey], overflowX: 'hidden'}}>
                                    <span className="table-responsive-stack-thead">{cols[colKey].name}:</span>
                                    {row[colKey]}
                                </td>
                            )
                        })}
                    </tr>
                ))}
                {(!data || !data.length) && <tr><td colSpan={Object.keys(cols).length} className="no-data-td">Nu s-au gasit date</td></tr>}
            </tbody>
        </table>
    );
};

export default Table;