import React from 'react';
import { connect } from 'react-redux';
import AdminUsers from '../Components/AdminUsers';
import SupervisorUsers from '../Components/SupervisorUsers';
import { USER_TYPE } from '../Utils/Constants';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};

const Users = ({...props}) => {
    const { user } = props;

    const fetchPage = () => {
        switch (user.role) {
            case USER_TYPE.SUPERVISOR:
                return <SupervisorUsers />;
            case USER_TYPE.ADMIN:
                return <AdminUsers />;
        }
    };

    return (
        <div>
            {fetchPage()}
        </div>
    )
}

export default connect(mapStateToProps)(Users);