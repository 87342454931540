import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

import AuthProvider from '../Utils/AuthProvider';
import Loader from '../Components/Loader';
import TopSection from './TopSection';
import { BASE_URL } from '../Utils/Constants';

const UserDashboard = ({...props}) => {
    const history = useHistory();

    const [summary, setSummary] = useState(null);
    const [summaryLoading, setSummaryLoading] = useState(null);
    const [fetchSummary, setFetchSummary] = useState(null);

    useEffect(() => {
        const getSummaryData = async () => {
            setSummaryLoading(true);

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/dashboard/summary/user'
            }); 

            if (!res.isOk) {
                return setSummaryLoading(false);
            }

            setSummary(res.data);
            setSummaryLoading(false);
        };
        
        getSummaryData();
    }, [fetchSummary]);

    const getNotifications = () => {
        if (!summary) return null;

        let notifications = [];

        let untakenTests = _.reduce(summary.tests, function(count, test) {
            return !test.testResultId ? ++count : count;
        }, 0);

        if (untakenTests !== 0) {
            notifications.push(
                <div key="tests" className="alert alert-danger alert-dismissible col">
                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <h5>
                        <i className="icon fas fa-ban" /> Atentie!
                    </h5>
                        Aveti {untakenTests} test(e) nesustinut(e). Va rugam sa abordati situatia.
                </div>
            );
        }

        return notifications.length ? notifications : null;
    };

    const getTestProperties = (test) => {
        let testProperties = {
            score: test.score || 0,
        };

        if (!test.score && test.score !== 0) {
            testProperties.badge = <span className="badge badge-danger mb-1 result-badge">Nesustinut</span>
            testProperties.progressBar = <div className="progress-bar bg-danger" style={{width: '0%'}}></div>
            return testProperties;
        } 

        let progress = parseFloat((test.score / test.maxPoints) * 100).toFixed(0);
        
        if (!test.passed) {
            testProperties.badge = <span className="badge badge-warning mb-1 result-badge">Respins</span>
            testProperties.progressBar = <div className="progress-bar bg-warning" style={{width: `${progress}%`}}></div>
        } else {
            testProperties.badge = <span className="badge badge-success mb-1 result-badge">Admis</span>
            testProperties.progressBar = <div className="progress-bar bg-success" style={{width: `${progress}%`}}></div>
        }

        return testProperties;
    };

    const redirectToTestPage = (testTemplateId) => {
        history.push('/test/' + testTemplateId);
    };

    const handleOpenDocument = async (documentId) => {
        let res = await AuthProvider.authReq({
            method: "GET",
            path: `v1/documentation/template/${documentId}/metered`
        });
        
        if (!res.isOk) return;

        let url = res.data;
        
        return window.open(`${BASE_URL}v1/documentation/preview/${url}`, "_blank");
    };

    return (
        <div className="content-wrapper">
            <TopSection pageTitle="Dashboard"/>
            <section className="content">
                <div className="container-fluid">

                    {!!summaryLoading && <div className="card" style={{minHeight: "5rem"}}>
                        <Loader />
                    </div>}

                    {!summaryLoading &&<div>
                        <div className="row">
                            {getNotifications()}
                        </div>

                        <div className="row">

                            <div className="callout callout-info col-12">
                                <h5>Rezultate teste</h5>
                                <p>In rubrica de mai jos puteti revizui rezultatele testelor sustinute cat si testele pe care nu le-ati sustinut inca</p>

                                <div className="row">
                                    {summary && _.map(summary.tests, (test) => {
                                        let testProperties = getTestProperties(test);

                                        return (
                                            <div key={test.templateId} className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="card card-info card-outline">    
                                                    <div className="card-header">
                                                        <h4 className="card-title w-100">
                                                            {test.title}
                                                        </h4>
                                                        <p className="font-italic">Minim {test.minPoints} puncte</p>
                                                    </div>
                                                    <div>
                                                        <div className="card-body">
                                                            <div className="progress-group">
                                                                {testProperties.badge}
                                                                <span className="float-right"><b>{testProperties.score}</b> din {test.maxPoints} Puncte</span>
                                                                <div className="progress progress-sm">
                                                                    {testProperties.progressBar}
                                                                </div>
                                                            </div>
                                                            {!!test.registerDate && 
                                                            <p className="w-100 font-italic">Sustinut la data de <strong>{test.registerDate}</strong></p>}
                                                        </div>
                                                        {<div className="card-footer">
                                                            <button 
                                                                className="btn btn-success w-100"
                                                                onClick={() => redirectToTestPage(test.templateId)}
                                                            >
                                                            { !test.testResultId ? 'Sustineti testul' : 'Refaceti testul' }
                                                            </button>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="callout callout-info col-12">
                                <h5>Documentatie</h5>
                                <p>In rubrica de mai jos puteti revizui si vizualiza documentele curente</p>
                                <div className="row">
                                    {summary && _.map(summary.documents, (document) => {
                                        return (
                                            <div key={document.id} className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="card card-info card-outline">    
                                                    <div className="card-header">
                                                        <h4 className="card-title w-100">
                                                            <strong>Titlu:</strong> {document.title}
                                                        </h4>
                                                    </div>
                                                    <div className="card-body pt-1 pb-1">
                                                        <div className="info-box m-0 pl-0 pr-0 dashboard-document-info-box" style={{boxShadow: 'none', background: 'transparent', borderRadius: 0}}>
                                                            <span className="info-box-icon bg-danger elevation-1">
                                                                <i className="far fa-file-pdf" />
                                                            </span>
                                                            <div className="info-box-content">
                                                                <span className="info-box-text">{document.extension}</span>
                                                                <span className="info-box-number">Categorie: {document.category}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <button 
                                                            className="btn btn-success w-100"
                                                            onClick={() => handleOpenDocument(document.id)}
                                                        >
                                                            Vizualizeaza
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        </div>
                        
                    </div>}
                </div>
            </section>
        </div>
    );
};

export default UserDashboard;