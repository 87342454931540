import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import _ from 'lodash';
import Loader from './Loader';
import Table from './Table';
import GeneralFilters from './GeneralFilters';
import AuthProvider from '../Utils/AuthProvider';
import { TEST_STATUS_MAPPING } from '../Utils/Constants';
import TopSection from './TopSection';

const testStatisticsCols = (setUserTestModalData) => {
    return {
        "name": {
            name: "Nume Utilizator",
        },
        "email": {
            name: "Email",
        },
        "testOk": {
            name: "Status teste",
            flexBasis: 10,
            handle: (row) => {
                if (!row.testOk) {
                    return <div className="col text-center"><i className="fas fa-times-circle text-danger" style={{fontSize: "2rem"}}/></div> 
                }

                return <div className="col text-center"><i className="fas fa-check text-success" style={{fontSize: "2rem"}}/></div> 
            }
        },
        "action": {
            name: "Actiuni",
            flexBasis: 15,
            handle: (row) => {
                return <div>
                    <button 
                        className="btn btn-warning mr-md-1 w-100" 
                        type="button" 
                        onClick={(e) => {
                            setUserTestModalData(row);
                        }}
                    >
                        Detalii
                    </button>
                </div>
            }
        }
    };
};

const testStatisticsModalCols = (redirectToViewTestPage) => {
    return {
        "name": {
            name: "Nume"
        },
        "minPoints": {
            name: "Punctaj minim",
            flexBasis: 12,
            handle: (row) => {
                return <div className="text-center"><strong>{row.minPoints}</strong></div>
            }
        },
        "maxPoints": {
            name: "Punctaj maxim",
            flexBasis: 12,
            handle: (row) => {
                return <div className="text-center"><strong>{row.maxPoints}</strong></div>
            }
        },
        "score": {
            name: "Punctaj obtinut",
            flexBasis: 12,
            handle: (row) => {
                return <div className="text-center"><strong>{row.score || '-'}</strong></div>
            }
        },
        "status": {
            name: "Rezultat",
            flexBasis: 12,
            handle: (row) => {
                switch (row.status) {
                    case TEST_STATUS_MAPPING.NOT_TAKEN:
                        return <span className="badge badge-danger result-badge w-100">Nesustinut</span>

                    case TEST_STATUS_MAPPING.FAILED:
                        return <span className="badge badge-warning result-badge w-100">Respins</span>

                    case TEST_STATUS_MAPPING.SUCCESSFUL:
                        return <span className="badge badge-success result-badge w-100">Admis</span>
                        
                    default: 
                        return null;
                }
            }
        },
        "action": {
            name: "Actiuni",
            flexBasis: 10,
            handle: (row) => {
                if (!row.testTemplateId) {
                    return null;
                }

                return <button 
                    className="btn btn-info mr-md-1 w-100 pl-0 pr-0" 
                    type="button" 
                    onClick={() => redirectToViewTestPage(row.id)} >
                    Vizualizeaza
                </button>
            }
        }
    };
};

const AdminTestStatistics = ({...props}) => {
    const history = useHistory();

    let searchCompanyId = new URLSearchParams(history.location.search).get('companyId');

    const [testStatsFilterData, setTestStatsFilterData] = useState({
        companyId: searchCompanyId || "",
        userRoleId: "",
        userId: ""
    });

    const [testStats, setTestStats] = useState(null);
    const [testStatsTableData, setTestStatsTableData] = useState(null);
    const [testStatsLoading, setTestStatsLoading] = useState(null);
    const [testStatsFetch, setTestStatsFetch] = useState(null);

    const [testStatsModalData, setTestStatsModalData] = useState(null);
    
    const userTestDetailsModalId = 'userTestDetailsModal';
    const userTestDetailsModalBtnRef = React.createRef();
    const userTestDetailsModalBtnCloseRef = React.createRef();

    useEffect(() => {
        const getTestStatsData = async () => {

            let query = {};
            
            if (!!testStatsFilterData.companyId) {
                query.companyId = testStatsFilterData.companyId;
            } 

            if (!!testStatsFilterData.userRoleId) {
                query.userRoleId = testStatsFilterData.userRoleId;
            }

            if (!!testStatsFilterData.userId) {
                query.userId = testStatsFilterData.userId;
            }

            setTestStatsLoading(true);

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/tests/statistics/admin',
                query
            });

            if (!res.isOk) {
                return setTestStatsLoading(false);
            }

            setTestStats(res.data);
            setTestStatsTableData(res.data);
            setTestStatsLoading(false);
        };

        getTestStatsData();
    }, [testStatsFetch, testStatsFilterData]);

    const onTestStatsFilterChange = (key, value) => {
        setTestStatsFilterData({
            ...testStatsFilterData,
            [key]: value
        })
    };
    
    const setUserTestModalData = (row) => {
        if (!row.id) return;

        let userData = _.find(testStatsTableData, { id: row.id });

        setTestStatsModalData({
            name: row.name,
            email: row.email,
            testResults: userData.testTemplates
        });

        userTestDetailsModalBtnRef.current.click();
    };

    const redirectToViewTestPage = (testResultId) => {
        userTestDetailsModalBtnCloseRef.current.click();
        history.push('/review-test/' + testResultId);
    };

    return (
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Statistici Teste"/>
                <section className="content">
                    <div className="container-fluid">

                        <div className="card">
                            <div className="card-header">
 
                                <div className="row mt-2 mb-4">
                                    <h3 className="card-title">Lista Utilizatori (Filtre)</h3>
                                </div>    
                        
                                <GeneralFilters
                                    data={testStatsFilterData}
                                    onChange={onTestStatsFilterChange}
                                ></GeneralFilters>
  
                            </div>
                            <div className="card-body table-responsive" style={{maxHeight: '430px'}}>
                                <div className={`card-body-wrapper ${testStatsLoading ? "loading" : ""}`}>
                                    <Table 
                                        cols={testStatisticsCols(setUserTestModalData)} 
                                        data={testStatsTableData} 
                                        customClasses="break-md"
                                    />
                                    {/* BEGIN MODAL */}
                                    <div>
                                        <button 
                                            type="button" 
                                            data-toggle="modal" 
                                            data-target={`#${userTestDetailsModalId}`} 
                                            ref={userTestDetailsModalBtnRef}
                                            style={{display: "none"}}
                                        />
                                        <div 
                                            className="modal fade" 
                                            id={`${userTestDetailsModalId}`} 
                                            tabIndex={-1} 
                                            role="dialog" 
                                            aria-labelledby={`${userTestDetailsModalId}Label`} 
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-xl" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 
                                                            className="modal-title" 
                                                            id={`${userTestDetailsModalId}Label`}
                                                        >
                                                            {`Rezultate teste ${testStatsModalData?.name} (${testStatsModalData?.email})`}
                                                        </h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <Table
                                                            cols={testStatisticsModalCols(redirectToViewTestPage)}
                                                            data={testStatsModalData?.testResults}
                                                            customClasses="break-md"
                                                        ></Table>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-secondary" 
                                                            data-dismiss="modal"
                                                            ref={userTestDetailsModalBtnCloseRef}
                                                        >
                                                            Inchide
                                                        </button>                                         
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END MODAL */}
                                </div>
                                {testStatsLoading && <Loader />}
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    );
}

export default AdminTestStatistics;
