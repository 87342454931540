import React, {useState, useEffect} from 'react';

import Loader from './Loader';
import Table from './Table';
import UploadDocumentSupervisorModal from './UploadDocumentSupervisorModal';
import SimpleModal from './SimpleModal';
import AuthProvider from '../Utils/AuthProvider';
import {NotificationManager} from 'react-notifications';
import { GENERIC_MESSAGE, BASE_URL } from '../Utils/Constants';
import TopSection from './TopSection';

const documentationTableCols = (handleOpenDocument, setToggleConfirmDeleteModal, setConfirmDeleteModalData) => {
    return {
        "documentName": {
            name: "Nume"
        },
        "categoryName": {
            name: "Categorie",
            flexBasis: 15,
        },
        "authorName": {
            name: "Autor",
            flexBasis: 15,
        },
        "userRoleNames": {
            name: "Drepturi",
            flexBasis: 15
        },
        "createDate": {
            name: "Data crearii",
            flexBasis: 15,
        },
        "action": {
            name: "Actiuni",
            flexBasis: 15,
            handle: (row) => {
                return <div>
                    <button 
                        className="btn btn-danger mr-md-1 mb-1 w-100" 
                        type="button" 
                        onClick={(e) => {
                            setConfirmDeleteModalData(row);
                            setToggleConfirmDeleteModal(true);
                        }}>
                        Sterge
                    </button>
                    <button 
                        className="btn btn-info w-100" 
                        type="button" 
                        onClick={(e) => {
                            handleOpenDocument(row)
                        }}>
                        Vizualizeaza
                    </button>
                </div>
            }
        }
    };
};

const AdminDocumentationTemplates = ({...props}) => {
    const [documentationLoading, setDocumentationLoading] = useState(null); 
    const [documentationData, setDocumentationData] = useState([]);
    const [fetchDocumentationData, setFetchDocumentationData] = useState(true);

    const [toggleConfirmDeleteModal, setToggleConfirmDeleteModal] = useState(false);
    const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null);

    useEffect(() => {
        const getDocuments = async () => {
            setDocumentationLoading(true);
            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/documentation/template/supervisor'
            });

            if (!res.isOk) return setDocumentationLoading(false);

            setDocumentationData(res.data);
            setDocumentationLoading(false);
        };

        getDocuments();
    }, [fetchDocumentationData]);

    const handleDeleteDocument = async (params) => {
        let res = await AuthProvider.authReq({
            method: "DELETE",
            path: 'v1/documentation/template/supervisor/' + params.id
        });

        if (!res.isOk) return;

        setFetchDocumentationData(!fetchDocumentationData);
        setToggleConfirmDeleteModal(false);
        NotificationManager.success(`Documentul a fost sters cu succes`, GENERIC_MESSAGE.success.title);
    };

    const handleOpenDocument = async (document) => {
        
        let res = await AuthProvider.authReq({
            method: "POST",
            path: 'v1/documentation/temp-url/' + document.id,
        });
        
        if (!res.isOk) return;

        let url = res.data;
        
        return window.open(`${BASE_URL}v1/documentation/preview/${url}`, "_blank");
    };

    const onRefresh = () => {
        setFetchDocumentationData(!fetchDocumentationData);
    };

    return (
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Documentatie"/>
                <section className="content">
                    <div className="container-fluid">
                        
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Lista Documente</h3>
                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${documentationLoading ? "loading" : ""}`}>
                                    <Table 
                                        cols={documentationTableCols(handleOpenDocument, setToggleConfirmDeleteModal, setConfirmDeleteModalData)} 
                                        data={documentationData} 
                                        customClasses="break-md"
                                    />

                                    <UploadDocumentSupervisorModal
                                        onRefresh={onRefresh}
                                    >
                                    </UploadDocumentSupervisorModal>

                                    <SimpleModal
                                        show={toggleConfirmDeleteModal}
                                        data={confirmDeleteModalData}
                                        onHide={() => setToggleConfirmDeleteModal(false)}
                                        onConfirm={(params) => handleDeleteDocument(params)}
                                        title={'Sterge Document'}
                                        confirmBtnTitle="Sterge"
                                        confirmBtnClass="btn-danger"
                                    >
                                        <p>Sunteti sigur ca vreti sa stergeti inregistrarea ?</p>
                                    </SimpleModal>
                                    
                                </div>
                                {documentationLoading && <Loader />}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AdminDocumentationTemplates;
