import {
	SET_USER,
	RESET_STORE
} from "../Actions/Types";

const INIT_STATE = {
	// user: {
	// 	type: 2
	// }
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				user: action.payload
			}
		case RESET_STORE:
			return INIT_STATE
		default:
			return { ...state };
	}
}
