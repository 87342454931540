import React, { Component } from 'react';
import { connect } from 'react-redux';

import AuthProvider from '../Utils/AuthProvider';
import { resetStore } from '../Actions/MainActions';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};

const Header = ({...props}) => {
    const logout = () => {
        AuthProvider.logout();
        props.resetStore();
    };


    if (!props.user) {
        return null;
    }

    return (
        <div>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" role="button" id="pushmenu">
                            <i className="fas fa-bars" />
                        </a>
                    </li>
                </ul>

                <div className="navbar-nav ml-auto">
                    <button type="button" className="btn btn-danger btn-sm logout-btn" onClick={() => logout()}>
                        <i className="fas fa-sign-out-alt pr-1" />
                        Logout
                    </button>
                </div>

            </nav>
        </div>
    );
}

export default connect(mapStateToProps, {
    resetStore
})(Header);