import React from 'react';
import { connect } from 'react-redux';
import AdminDocumentationStatistics from '../Components/AdminDocumentationStatistics';
import SupervisorDocumentationStatistics from '../Components/SupervisorDocumentationStatistics';
import { USER_TYPE } from '../Utils/Constants';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};

const DocumentationStatistics = ({...props}) => {
    const { user } = props;

    const fetchPage = () => {
        switch (user.role) {
            case USER_TYPE.SUPERVISOR:
                return <SupervisorDocumentationStatistics />;
            case USER_TYPE.ADMIN:
                return <AdminDocumentationStatistics />;
        }
    };

    return (
        <div>
            {fetchPage()}
        </div>
    )
}

export default connect(mapStateToProps)(DocumentationStatistics);