import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import AuthProvider from '../Utils/AuthProvider';
import ReviewTestCard from '../Components/ReviewTestCard';
import { GENERIC_MESSAGE } from '../Utils/Constants';
import TopSection from '../Components/TopSection';

const ReviewTest = ({...props}) => {    
    const { testResultId } = useParams();
    
    const [testData, setTestData] = useState(null);
    const [testDataLoading, setTestDataLoading] = useState(false);
    const [testDataFetching, setTestDataFetching] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setTestDataLoading(true);
            
            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/tests/review/' + testResultId
            });
            
            if (!res.isOk) {
                return setTestDataLoading(false);
            }

            setTestData(res.data);
            return setTestDataLoading(false);
        };
        getData();
    }, [testDataFetching]);

    return (
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Rezultat Test"/>
                <section className="content">
                    <div className="container-fluid">
                        <ReviewTestCard 
                            data={testData}
                            loading={testDataLoading}
                        >/</ReviewTestCard>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ReviewTest;