import React, {useState, useEffect} from 'react';
import _ from 'lodash';

import AuthProvider from '../Utils/AuthProvider';
import {NotificationManager} from 'react-notifications';
import { VALIDATION_MESSAGES, GENERIC_MESSAGE, USER_TYPE } from '../Utils/Constants';

const UploadDocumentAdminModal = ({...props}) => {
    const { onRefresh } = props;

    const closeModalBtnRef = React.createRef();

    const [loading, setLoading] = useState(null); 

    const [documentCategories, setDocumentCategories] = useState(null);

    const [formData, setFormData] = useState({
        file: null,
        categoryId: null,
        name: null,
        userRoles: {
            [USER_TYPE.ADMIN]: true,
            [USER_TYPE.SUPERVISOR]: true,
        }
    });

    const [formErrors, setFormErrors] = useState({
        file: null,
        categoryId: null,
        name: null
    });

    useEffect(() => {
        const getDocumentCategories = async () => {
            let documentCategories = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/documentation/categories'
            });

            if (!documentCategories.isOk) return;

            setDocumentCategories(documentCategories.data);
        };

        getDocumentCategories();
    }, []);

    const onPermissionsChange = (value, key) => {
        let userRoles = formData.userRoles;

        if (!!value) {
            userRoles[key] = 1;
        } else {
            delete userRoles[key];
        }

        setFormData({
            ...formData,
            userRoles: userRoles
        })
    }

    const onChange = (e) => {
        const { name, value, files } = e.target;

        if (formData.hasOwnProperty(name)) {   
            return setFormData(formData => ({
                ...formData,
                [name]: files ? files[0] : value 
            }));
        }
    };

    const onSubmit = async (e) => {
        setLoading(true);

        let errors = {};

        for (let key in formData) {
            if (!formData[key] || formData[key] === -1) {
                errors[key] = true;
            }
        }

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return setLoading(false);
        }

        let uploadData = _.clone(formData);
        let blobFormData = new FormData();
        
        blobFormData.append("userRoles", JSON.stringify(uploadData.userRoles));

        for (var key in uploadData) {
            if (!blobFormData.has(key)) {
                blobFormData.append(key, uploadData[key]);
            }
        }

        closeModalBtnRef.current.click();

        let res = await AuthProvider.authReq({
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            path: 'v1/documentation/template/supervisor'
        }, blobFormData); 

        if (!res.isOk) {
            return setLoading(false);
        }

        NotificationManager.success(`Documentul a fost urcat cu succes`, GENERIC_MESSAGE.success.title);

        refresh();

        setLoading(false);

        onRefresh();
    };

    const refresh = () => {
        setFormErrors({
            file: null,
            categoryId: null,
            name: null
        });

        setFormData({
            file: null,
            categoryId: null,
            name: null,
            userRoles: {}
        });
    };

    return (
        <div>
            
            <button 
                className="btn btn-success mt-md-3 w-100"
                type="button" 
                data-toggle="modal" 
                data-target="#add-document-modal"
                onClick={() => refresh()} >
                    Adauga document
            </button>

            <div 
                className="modal fade" 
                id="add-document-modal" 
                tabIndex={-1} 
                role="dialog" 
                aria-labelledby="add-document-modal-label" 
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        {/* BEGIN MODAL HEADER */}
                        <div className="modal-header">
                            <h5 
                                className="modal-title" 
                                id="add-document-modal-label"
                            >
                                Adauga document
                            </h5>
                            <button 
                                type="button" 
                                className="close" 
                                data-dismiss="modal" 
                                aria-label="Close"
                                ref={closeModalBtnRef} 
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        {/* END MODAL HEADER*/}

                        {/* BEGIN MODAL BODY */}
                        <div className="modal-body">

                            <form>

                                <div className="form-row">

                                    <div className="col-md-6 col-sm-12 mb-3">
                                        <label>Categorie</label>
                                        <select 
                                            className={`custom-select ${formErrors.categoryId ? "is-invalid" : ""}`}
                                            value={formData.categoryId || -1}
                                            name="categoryId"
                                            disabled={!!loading}
                                            onChange={(e) => onChange(e)}
                                        >
                                            <option value={-1}>Alege...</option>
                                            {documentCategories && documentCategories.map((documentCategory) => {
                                                return <option key={documentCategory.id} value={documentCategory.id}>{documentCategory.description}</option>
                                            })}
                                        </select>
                                        {formErrors.categoryId && <div className="invalid-feedback">Selectati o categorie</div>}
                                    </div>

                                    <div className="col-md-6 col-sm-12 mb-3">
                                        <label>Nume</label>
                                        <input 
                                            type="text" 
                                            className={`form-control ${formErrors.name ? "is-invalid" : ""}`}
                                            value={formData.name || ""}
                                            placeholder="Nume" 
                                            name="name"
                                            disabled={!!loading}
                                            onChange={(e) => onChange(e)}
                                        />
                                        {formErrors.name && <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div>}
                                    </div>

                                    <div className="col-md-3 col-sm-12 mb-3">
                                        
                                        <label>Permisiuni</label>
                                        <div className="form-group">
                                                                                        
                                            <div className="custom-control custom-checkbox mb-1">
                                                <input 
                                                    className="custom-control-input" 
                                                    type="checkbox" 
                                                    id="supervisorRightCheckbox" 
                                                    checked
                                                    disabled
                                                    onChange={(e) => onPermissionsChange(e.currentTarget.checked, USER_TYPE.SUPERVISOR)}
                                                />
                                                <label htmlFor="supervisorRightCheckbox" className="custom-control-label">Supervizor</label>
                                            </div>
                                            
                                            <div className="custom-control custom-checkbox">
                                                <input 
                                                    className="custom-control-input" 
                                                    type="checkbox" 
                                                    id="userRightCheckbox" 
                                                    disabled={!!loading}
                                                    onChange={(e) => onPermissionsChange(e.currentTarget.checked, USER_TYPE.USER)}
                                                />
                                                <label htmlFor="userRightCheckbox" className="custom-control-label">Utilizator</label>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-md-9 col-sm-12">
                                        <label>Fisier</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input 
                                                    type="file" 
                                                    className={`custom-file-input ${formErrors.file ? "is-invalid" : ""}`} 
                                                    id="exampleInputFile" 
                                                    name="file"
                                                    disabled={!!loading}
                                                    onChange={(e) => onChange(e)}
                                                />
                                                <label className="custom-file-label" htmlFor="exampleInputFile">{formData.file?.name || 'Alege fisier'}</label>
                                            </div>
                                            {formErrors.file && <div className="invalid-feedback" style={{display: 'block'}}>Selectati un fisier</div>}
                                        </div>
                                    </div>

                                </div>

                            </form>

                        </div>
                        {/* END MODAL BODY*/}

                        {/* BEGIN MODAL FOOTER*/}
                        <div className="modal-footer">

                            <button 
                                type="button" 
                                className="btn btn-primary" 
                                onClick={() => onSubmit()}>
                                    Salveaza
                            </button>    

                            <button 
                                type="button" 
                                className="btn btn-secondary" 
                                data-dismiss="modal" 
                                onClick={() => refresh()} >
                                    Inchide
                            </button>                                         
                        </div>
                        {/* END MODAL FOOTER*/}

                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default UploadDocumentAdminModal;