import React from 'react';
import _ from 'lodash';
import Loader from '../Components/Loader';

const ReviewTestCard = ({...props}) => {
    const { data, loading } = props;

    if (!data) {
        return null;
    } else if (loading) {
        return (
            <div className="card">
                <div className="card-body">
                    <div className={`card-body-wrapper loading`}>
                    </div>
                    <Loader/>
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className='card-body-wrapper'>
                    <div className="col-12">
                        <form>
                            <h3 className="text-center mt-2">{data.title}</h3>
                            <h6 className="text-center font-italic mb-2">Minim {data.minPoints} de puncte / Maxim {data.maxPoints} de puncte</h6>
                            <h6 className="text-center mb-2 text-info">{data.score} puncte obtinute</h6>
                            
                            <div className="row">
                                {!!data.passed ? <span className="badge badge-success mb-1 result-badge float-right">Admis</span>
                                : <span className="badge badge-warning mb-1 result-badge">Respins</span>}
                            </div>
                            
                            <div className="row mb-3">
                                <div className="col-12">
                                    <h6 className="float-left">{data.userName} ({data.userEmail})</h6>
                                    <h6 className="float-right">{data.createDate}</h6>
                                </div>
                            </div>
                            
                            {data.data.map((article, articleNr) => {
                                let answerIsIncorrect = _.some((article.answers), (answer) => {
                                    return (!!answer.correct && !answer.selected) ||
                                        (!answer.correct && !!answer.selected);
                                });
                                
                                let redactedScore = null;
                                if (answerIsIncorrect) {
                                    redactedScore = Math.ceil(parseFloat(data.maxPoints / Object.keys(data.data).length)); 
                                }

                                return (
                                    <div key={articleNr} className="card card-info card-outline">    
                                        <div className="card-header">
                                            <h4 className="card-title">
                                                {`${articleNr+1}. ${article.question}`}
                                            </h4>
                                            {redactedScore && <h4 className="card-title float-right text-danger">-{redactedScore} puncte</h4>}
                                        </div>
                                        <div>
                                            <div className="card-body">
                                                {article.answers.map((answer, answerNr) => {
                                                    let labelClass = '';
                                                    let labelIcon = null; 

                                                    if (!!answer.selected && !answer.correct) {
                                                        labelClass = 'text-danger font-weight-bold';
                                                        labelIcon = <i className="fas fa-times text-danger ml-sm-2"></i>;
                                                    } else if (!!answer.selected && !!answer.correct) {
                                                        labelClass = 'text-success font-weight-bold';
                                                        labelIcon = <i className="fas fa-check text-success ml-sm-2"></i>;
                                                    } else if (!!answer.correct) {
                                                        labelClass = 'text-success font-weight-bold';
                                                    }                                                    

                                                    return (
                                                        <div key={answerNr} className="custom-control custom-checkbox mb-2">
                                                            <input 
                                                                className="custom-control-input" 
                                                                type="checkbox" 
                                                                checked={!!answer.selected}
                                                                disabled
                                                                id={`customCheckbox${''+articleNr+answerNr}`} />
                                                            <label
                                                                htmlFor={`customCheckbox${''+articleNr+answerNr}`} 
                                                                className={`custom-control-label ${labelClass}`}>
                                                                <strong>{`${answer.index})`}</strong> {`${answer.description}`}
                                                            </label>
                                                            {labelIcon}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewTestCard;