import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = ({ MainReducer }) => {
    const { user } = MainReducer;
    return { user };
};

const PrivateRoute = ({component: Component, ...props}) => {
    let isAuth = !!props.user;
    
    if (!isAuth) {
        return (
            <Redirect to="/login" />
        );
    }

    if (props.roles && (!props.user.user || props.roles.indexOf(props.user.user.role)) === -1) {
        return (
            <Redirect to="/login" />
        );
    }

    return (
        <Route {...props} render={props => (
           <Component {...props} />
        )} />
    );
};

export default connect(mapStateToProps)(PrivateRoute);