const TopSection = ({...props}) => {
    const { pageTitle } = props;
    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-6">
                        <img src="../img/wma_logo.png" className="ml-auto mr-auto" style={{height: '3.5rem'}}/>
                    </div>
                    <div className="col-6">
                        <h1 className="text-right" style={{marginTop: '0.82rem'}}>{pageTitle || ''}</h1>
                        {/* <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active">Dashboard v1</li>
                        </ol> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopSection;