import React, {useState, useEffect} from 'react';

import Loader from './Loader';
import Table from './Table';
import UploadDocumentAdminModal from './UploadDocumentAdminModal';
import GeneralFilters from '../Components/GeneralFilters';
import SimpleModal from './SimpleModal';
import AuthProvider from '../Utils/AuthProvider';
import {NotificationManager} from 'react-notifications';
import { GENERIC_MESSAGE, BASE_URL } from '../Utils/Constants';
import TopSection from './TopSection';

const documentationTableCols = (handleDeleteDocument, handleOpenDocument, setToggleConfirmDeleteModal, setConfirmDeleteModalData) => {
    return {
        "documentName": {
            name: "Nume"
        },
        "categoryName": {
            name: "Categorie",
            flexBasis: 15,
        },
        "companyName": {
            name: "Firma",
            flexBasis: 12,
        },
        "authorName": {
            name: "Autor",
            flexBasis: 12,
        },
        "userRoleNames": {
            name: "Drepturi",
            flexBasis: 15
        },
        "createDate": {
            name: "Data crearii",
            flexBasis: 15,
        },
        "action": {
            name: "Actiuni",
            flexBasis: 10,
            handle: (row) => {
                return <div>
                    <button 
                        className="btn btn-danger w-100 mb-1" 
                        type="button" 
                        onClick={(e) => {
                            setConfirmDeleteModalData(row);
                            setToggleConfirmDeleteModal(true);
                        }}>
                        Sterge
                    </button>
                    <button 
                        className="btn btn-info w-100" 
                        type="button" 
                        onClick={(e) => {
                            handleOpenDocument(row)
                        }}>
                        Vizualizeaza
                    </button>
                </div>
            }
        }
    };
};

const AdminDocumentationTemplates = ({...props}) => {
    const [documentationLoading, setDocumentationLoading] = useState(null); 
    const [documentationData, setDocumentationData] = useState([]);
    const [fetchDocumentationData, setFetchDocumentationData] = useState(true);

    const [documentsFilterData, setDocumentsFilterData] = useState({
        companyId: "",
    });

    const [toggleConfirmDeleteModal, setToggleConfirmDeleteModal] = useState(false);
    const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null);

    useEffect(() => {
        const getDocuments = async () => {
            setDocumentationLoading(true);

            let query = {};

            if (!!documentsFilterData.companyId) {
                query.companyId = documentsFilterData.companyId;
            }

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/documentation/template/admin',
                query
            });

            if (!res.isOk) return setDocumentationLoading(false);

            setDocumentationData(res.data);
            setDocumentationLoading(false);
        };

        getDocuments();
    }, [fetchDocumentationData, documentsFilterData]);

    const handleDeleteDocument = async (params) => {
        let res = await AuthProvider.authReq({
            method: "DELETE",
            path: 'v1/documentation/template/admin/' + params.id,
        });

        if (!res.isOk) return;

        setFetchDocumentationData(!fetchDocumentationData);
        setToggleConfirmDeleteModal(false);
        NotificationManager.success(`Documentul a fost sters cu succes`, GENERIC_MESSAGE.success.title);
    };

    const handleOpenDocument = async (document) => {
        
        let res = await AuthProvider.authReq({
            method: "POST",
            path: 'v1/documentation/temp-url/' + document.id,
        });
        
        if (!res.isOk) return;

        let url = res.data;
        
        return window.open(`${BASE_URL}v1/documentation/preview/${url}`, "_blank");
    };

    const onRefresh = () => {
        setFetchDocumentationData(!fetchDocumentationData);
    };

    const onDocumentsFilterChange = (key, value) => {
        setDocumentsFilterData({
            ...documentsFilterData,
            [key]: value
        })
    };

    return (
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Documentatie"/>
                <section className="content">
                    <div className="container-fluid">
                        
                        <div className="card">
                            <div className="card-header">

                                <div className="row mt-2 mb-4">
                                    <h3 className="card-title">Lista Documente (Filtre)</h3>
                                </div>    

                                <GeneralFilters
                                    data={documentsFilterData}
                                    onChange={onDocumentsFilterChange}
                                ></GeneralFilters>

                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${documentationLoading ? "loading" : ""}`}>
                                    <Table 
                                        cols={documentationTableCols(handleDeleteDocument, handleOpenDocument, setToggleConfirmDeleteModal, setConfirmDeleteModalData)} 
                                        data={documentationData} 
                                        customClasses="break-md"
                                    />

                                    <UploadDocumentAdminModal
                                        onRefresh={onRefresh}
                                    >
                                    </UploadDocumentAdminModal>

                                    <SimpleModal
                                        show={toggleConfirmDeleteModal}
                                        data={confirmDeleteModalData}
                                        onHide={() => setToggleConfirmDeleteModal(false)}
                                        onConfirm={(params) => handleDeleteDocument(params)}
                                        title={'Sterge Document'}
                                        confirmBtnTitle="Sterge"
                                        confirmBtnClass="btn-danger"
                                    >
                                        <p>Sunteti sigur ca vreti sa stergeti inregistrarea ?</p>
                                    </SimpleModal>

                                </div>
                                {documentationLoading && <Loader />}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AdminDocumentationTemplates;
