import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import Table from '../Components/Table';
import Loader from '../Components/Loader';
import AuthProvider from '../Utils/AuthProvider';
import { useForm } from "react-hook-form";
import { VALIDATION_MESSAGES, GENERIC_MESSAGE, USER_INVITATION_STATUS_DESCRIPTION } from '../Utils/Constants';
import {NotificationManager} from 'react-notifications';
import TopSection from './TopSection';
import SimpleModal from './SimpleModal';

const userTableCols = (setDeleteUserModalData) => ({
    "name": {
        name: "Nume"
    },
    "email": {
        name: "Email"
    },
    "createDate": {
        name: "Data inrolare",
        flexBasis: 15
    },
    "action": {
        name: "Actiuni",
        flexBasis: 8,
            handle: (row) => {
                return <div>
                    <button
                        className="btn btn-danger mr-md-1 w-100"
                        type="button"
                        onClick={(e) => {
                            setDeleteUserModalData(row);
                        }}
                    >
                        Sterge
                    </button>
                </div>
            }
    }
});

const invitationTableCols = {
    "email": {
        name: "Email"
    },
    "createDate": {
        name: "Data Invitatiei",
        flexBasis: 20
    },
    "userInvitationStatusId": {
        name: "Status",
        flexBasis: 15,
        handle: (row) => {
            switch (row.userInvitationStatusId) {
                case USER_INVITATION_STATUS_DESCRIPTION.INVITATION_SENT:
                    return <span className="badge badge-warning result-badge">Invitatie trimisa</span>;
                default:
                    return false;
            }
        }
    }
};

const SupervisorUsers = ({...props}) => {
    const [sendInvitationLoading, setSendInvitationLoading] = useState(null);

    const [getInvitationLoading, setGetInvitationLoading] = useState(true);
    const [fetchInvitationData, setFetchInvitationData] = useState(true);
    const [invitationData, setInvitationData] = useState([]);


    const [getUsersLoading, setGetUsersLoading] = useState(true);
    const [fetchUsersData, setFetchUsersData] = useState(true);
    const [usersData, setUsersData] = useState([]);

    const [deleteUserModalData, setDeleteUserModalData] = useState(false);
    
    const {
        register: sendInviteRegister,
        handleSubmit: sendInviteHandleSubmit,
        errors: sendInviteErrors
    } = useForm();

    useEffect(() => {
        const getData = async () => {
            setGetUsersLoading(true);

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/users/supervisor'
            });

            if (!res.isOk) {
                return setGetUsersLoading(false);
            }
            
            setUsersData(res.data);
            setGetUsersLoading(false);
        };

        getData();
    }, [fetchUsersData]);

    useEffect(() => {
        const getData = async () => {
            setGetInvitationLoading(true);

            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/users/invite/supervisor'
            });

            if (!res.isOk) {
                return setGetInvitationLoading(false);
            }

            setInvitationData(res.data);
    
            setGetInvitationLoading(false);
        };

        getData();
    }, [fetchInvitationData]);

    const onSendInviteHandleSubmit = async (data) => {
        setSendInvitationLoading(true);

        let res = await AuthProvider.authReq({
            method: "POST",
            path: 'v1/users/invite/supervisor'
        }, data);

        if (!res.isOk) {
            return setSendInvitationLoading(false);
        }

        NotificationManager.success(`Invitatia a fost trimisa catre ${data.email}`, GENERIC_MESSAGE.success.title);

        setSendInvitationLoading(false);

        setFetchInvitationData(!fetchInvitationData);
    };

    const deleteUser = async () => {
        const deleteUserId = deleteUserModalData.id;

        setDeleteUserModalData(undefined);

        let roles = await AuthProvider.authReq({
            method: "DELETE",
            path: 'v1/users/supervisor',
        }, {
            userId: deleteUserId
        });

        if (!roles.isOk) return;

        NotificationManager.success(`Utilizatorul a fost sters cu succes`, GENERIC_MESSAGE.success.title);

        setFetchUsersData(!fetchUsersData);
    };

    return (
        <>
        <div>
            <div className="content-wrapper">
                <TopSection pageTitle="Utilizatori"/>

                <section className="content">
                    <div className="container-fluid">

                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Lista Utilizatori</h3>
                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${getUsersLoading ? "loading" : ""}`}>
                                    <Table cols={userTableCols(setDeleteUserModalData)} data={usersData} customClasses="break-md"/>
                                </div>
                                {getUsersLoading && <Loader />}
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Lista Invitatii</h3>
                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${getInvitationLoading ? "loading" : ""}`}>
                                    <Table cols={invitationTableCols} data={invitationData} customClasses="break-md"/>
                                </div>
                                {getInvitationLoading && <Loader />}
                            </div>
                        </div>

                        <div className="card col-12">
                            <div className="card-header">
                                <h3 className="card-title">Invita Utilizatori</h3>
                            </div>
                            <div className="card-body">
                                <div className={`card-body-wrapper ${sendInvitationLoading ? "loading" : ""}`}>
                                    <form onSubmit={sendInviteHandleSubmit(onSendInviteHandleSubmit)}>

                                        <div className="form-row mb-3">
                                            <div className="input-group col-lg-6 col-md-6 col-sm-12">

                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">Email</div>
                                                </div>

                                                <input 
                                                    type="text" 
                                                    name="email"
                                                    className={`form-control ${sendInviteErrors.email ? "is-invalid" : ""}`} 
                                                    placeholder="Email" 
                                                    aria-label="Email" 
                                                    aria-describedby="basic-addon2" 
                                                    ref={sendInviteRegister({ required: true })}
                                                />
                                                {sendInviteErrors.email && <div className="invalid-feedback">{VALIDATION_MESSAGES.required}</div>}
                                            </div>

                                        </div>

                                        <div className="input-group-append float-right">
                                            <button className="btn btn-primary" type="submit">Trimite Invitatie</button>
                                        </div>

                                    </form>
                                </div>
                                {sendInvitationLoading && <Loader />}
                            </div>
                        </div>
                    
                    </div>
                </section>
            </div>
        </div>
        <SimpleModal
            show={!!deleteUserModalData}
            data={undefined}
            onHide={() => setDeleteUserModalData(undefined)}
            onConfirm={deleteUser}
            title={'Sterge Utilizator'}
            confirmBtnTitle="Sterge"
            confirmBtnClass="btn-danger"
        >
            <p>Sunteti sigur ca vreti sa stergeti utilizatorul <strong>{`${deleteUserModalData?.name || ''}`}</strong>?</p>
        </SimpleModal>
        </>
    );
}

export default SupervisorUsers;
