import React from 'react';
import Loader from '../Components/Loader';

const TestCard = ({...props}) => {
    const { data, loading } = props;

    if (!data) {
        return null;
    } else if (loading) {
        return (
            <div className="card">
                <div className="card-body min-vh-100">
                    <div className={`card-body-wrapper loading`}>
                    </div>
                    <Loader/>
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className='card-body-wrapper'>
                    <div className="col-12">

                        <form onSubmit={props.onSubmit}>
                            <h3 className="text-center mt-2">{data.data.title}</h3>
                            <h6 className="text-center font-italic mb-4">Minim {data.data.points} de puncte</h6>
                            {data.data.articles.map((article, articleNr) => {
                                return (
                                    <div key={articleNr} className="card card-info card-outline">    
                                        <div className="card-header">
                                            <h4 className="card-title w-100">
                                                {`${articleNr+1}. ${article.question}`}
                                            </h4>
                                        </div>
                                        <div>
                                            <div className="card-body">
                                                {article.answers.map((answer, answerNr) => {
                                                    return (
                                                        <div key={answerNr} className="custom-control custom-checkbox mb-2">
                                                            <input 
                                                                className="custom-control-input" 
                                                                type="checkbox" 
                                                                id={`customCheckbox${''+articleNr+answerNr}`} 
                                                                onChange={(e) => props.onChange(e, articleNr, answerNr)}/>
                                                            <label
                                                                htmlFor={`customCheckbox${''+articleNr+answerNr}`} 
                                                                className="custom-control-label">
                                                                <strong>{`${answer.index})`}</strong> {`${answer.description}`}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <button className="btn btn-success w-100" type="submit">Trimite</button> 
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestCard;