import {
    SET_USER,
    RESET_STORE
} from './Types';

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
});

export const resetStore = () => ({
    type: RESET_STORE
});