import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import AuthProvider from '../Utils/AuthProvider';
import TestCard from '../Components/TestCard';
import { GENERIC_MESSAGE } from '../Utils/Constants';

const Test = ({...props}) => {    
    const { templateId } = useParams();
    const history = useHistory();
    
    const [testTemplateData, setTestTemplateData] = useState(null);
    const [testTemplateLoading, setTestTemplateLoading] = useState(false);
    const [testTemplateFetching, setTestTemplateFetching] = useState(false);

    const [testData, setTestData] = useState(null);

    useEffect(() => {
        let bodySelector = document.getElementsByClassName("layout-fixed");
        if (bodySelector.length) {
            bodySelector[0].classList.add('sidebar-collapse');
        }
        return () => {
            let bodySelector = document.getElementsByClassName("layout-fixed");
            if (bodySelector.length) {
                bodySelector[0].classList.remove("sidebar-collapse");
            }
        }
      }, []);

    useEffect(() => {
        const getData = async () => {
            setTestTemplateLoading(true);
            
            let res = await AuthProvider.authReq({
                method: "GET",
                path: 'v1/tests/template/' + templateId
            });
            
            if (!res.isOk) {
                return setTestTemplateLoading(false);
            }

            let testData = res.data.data.articles.map((x) => {return {};})
            setTestData(testData);

            setTestTemplateLoading(false);
            return setTestTemplateData(res.data);
        };
        getData();
    }, [testTemplateFetching]);

    const onChooseAnswer = (e, articleNr, answerNr) => {
        let data = testData;
        data[articleNr][answerNr] = e.target.checked;
        setTestData(data);
    };

    const onTestSubmit = async (e) => {
        e.preventDefault();
        setTestTemplateLoading(true);
            
        let res = await AuthProvider.authReq({
            method: "POST",
            path: 'v1/tests/template/submit'
        }, {
            templateId: templateId,
            data: testData
        });
        
        if (!res.isOk) {
            return setTestTemplateLoading(false);
        }

        NotificationManager.success(`Testul a fost trimis catre validare`, GENERIC_MESSAGE.success.title);

        return setTimeout(() => history.push('/'), 1500);
    };

    return (
        <div>
            <div className="content-wrapper">
                <div className="content-header">
                    {/* <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Sabloane Teste</h1>
                            </div>
                        </div>
                    </div> */}
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <TestCard 
                            data={testTemplateData}
                            loading={testTemplateLoading}
                            onChange={onChooseAnswer}
                            onSubmit={onTestSubmit}
                        >/</TestCard>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Test;