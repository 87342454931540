// import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Menu from './Components/Menu';
import Footer from './Components/Footer';
import Dashboard from './Pages/Dashboard';
import TestTemplates from './Pages/TestTemplates';
import SupervisorTestTemplates from './Pages/SupervisorTestTemplates';
import Test from './Pages/Test';
import ReviewTest from './Pages/ReviewTest';
import TestStatistics from './Pages/TestStatistics';
import DocumentationTemplates from './Pages/DocumentationTemplates';
import DocumentationStatistics from './Pages/DocumentationStatistics';
import Login from './Pages/Login';
import Register from './Pages/Register';
import ResetPassword from './Pages/ResetPassword';
import ForgotPassword from './Pages/ForgotPassword';
import Users from './Pages/Users';
import Companies from './Pages/Companies';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './Components/PrivateRoute';
import * as CONSTANTS from './Utils/Constants';
import { connect } from 'react-redux';
import { setUser } from './Actions/MainActions';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

const mapStateToProps = ({ MainReducer }) => {
  const { user } = MainReducer;
  return { user };
};  

const App = ({...props}) => {
  // very hackish
  if (!props.user) {
    document.body.classList.add('login-page');
    document.body.classList.remove('hold-transition');
    document.body.classList.remove('sidebar-mini');
  } else {
    document.body.classList.remove('login-page');
    document.body.classList.add('hold-transition');
    document.body.classList.add('sidebar-mini');
  }

  let userFromStorage = localStorage.getItem("user");
  if (!props.user && userFromStorage ) {
    props.setUser(JSON.parse(userFromStorage).user);
  }

  return (
    <div className="wrapper">
      <Header/>

      <BrowserRouter>
      
        <Switch>
          <PrivateRoute component={Dashboard} exact={true} path="/"/>
          <PrivateRoute component={Users} path="/users" roles={[CONSTANTS.USER_TYPE.ADMIN]} />
          <PrivateRoute component={Companies} path="/companies" roles={[CONSTANTS.USER_TYPE.ADMIN]} />

          <PrivateRoute component={TestTemplates} path="/test-templates" roles={[CONSTANTS.USER_TYPE.ADMIN]} />
          <PrivateRoute component={SupervisorTestTemplates} path="/test-template" roles={[CONSTANTS.USER_TYPE.SUPERVISOR]} />

          <PrivateRoute component={TestStatistics} path="/test-statistics" roles={[CONSTANTS.USER_TYPE.ADMIN]} />

          <PrivateRoute component={DocumentationTemplates} path="/documentation-templates" roles={[CONSTANTS.USER_TYPE.ADMIN]} />
          <PrivateRoute component={DocumentationStatistics} path="/documentation-statistics" roles={[CONSTANTS.USER_TYPE.ADMIN]} />

          <PrivateRoute component={Test} path="/test/:templateId" roles={[CONSTANTS.USER_TYPE.USER]} />
          <PrivateRoute component={ReviewTest} path="/review-test/:testResultId" roles={[CONSTANTS.USER_TYPE.ADMIN, CONSTANTS.USER_TYPE.SUPERVISOR]} />

          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/register/:code" component={Register} />
          <Route path="/reset-password/:ref" component={ResetPassword} />
        </Switch> 

        <Menu/>  

      </BrowserRouter>

      <Footer/>

      <NotificationContainer/>
    </div>
  );
}

export default connect(mapStateToProps, {
  setUser
})(App);
